import { TextField } from "@mui/material";
import { useForm } from "../../../../hooks/useForm/useForm";
import { FormProps } from "../../../form-types";
import { Database, supabase } from "../../../../../../lib/supabase";
import { LanguageSelect } from "../../../form-input-components/selects/language.select";
import { InvoiceSettingsBGroup } from "../input-components/invoice-settings-b.group";
import { InvoiceSettingsAGroup } from "../input-components/invoice-settings-a.group";
import { ClientNameAndAddressSection } from "../input-components/client-name-and-address.section";
import { FormSectionWithSaveButton } from "../../../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { FormSectionWithTitle } from "../../../../components/form-section-with-title/form-section-with-title.component";
import { SupabaseTableEnum } from "../../../../../../lib/supabase/supabaseTypes";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";
import { useState } from "react";
import { useCustomDialogContext } from "@/dentlab/src/components/dialog-components/custom-dialog/custom-dialog.component";
import { ImageInput } from "../input-components/image-input";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { StorageBucketsEnum } from "@/dentlab/src/types/enums";
import { Logger } from "@/lib/logger/Logger";

export const ClientForm: React.FC<FormProps> = ({
	onSave = () => {},
	submitRef = null,
}) => {
	const { organizationId, upsertClient } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
		upsertClient: state.upsertClient,
	}));
	const [profilePicFile, setProfilePicFile] = useState<File | null>(null);
	const { setIsPendingChanges } = useCustomDialogContext();
	const navigate = useNavigate();
	const {
		handleInputChange,
		handleCheckboxChange,
		handleCreate,
		formData,
		formErrors,
		handleValueChange,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["clients"]["Row"]>(
		{
			// code is set by supabase trigger and function
			organization_id: organizationId,
		},
		SupabaseTableEnum.CLIENTS,
		undefined,
		undefined,
		"Auftraggeber",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (profilePicFile) {
			if (!formData.avatar_path) {
				showNotification({
					message: "Fehler beim Hochladen des Avatars",
					type: "error",
				});
				return;
			}
			const { error } = await supabase.storage
				.from(StorageBucketsEnum.ORG_V1)
				.upload(
					`${organizationId}/${formData.avatar_path}`,
					profilePicFile
				);

			if (error) {
				showNotification({
					message: "Fehler beim Hochladen des Avatars",
					type: "error",
				});
				Logger.error("Error uploading avatar", error);
				return;
			}
		}
		delete formData.search_vector;
		const { success, data } = await handleCreate(event);
		if (success && data) {
			const client = data[0];
			delete client.search_vector;
			onSave(client);
			await upsertClient(client);
			navigate(`/client/${client.id}`);
		} else {
			showNotification({
				message: "Fehler beim Speichern des Auftraggebers",
				type: "error",
			});
		}
	};

	const handleFileChange = (file: File | null) => {
		setProfilePicFile(file);
		handleValueChange("avatar_path", `avatars/${uuidv4()}/avatar`);
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			onResetForm={resetFormData}
		>
			<ImageInput onFileChange={handleFileChange} />
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<CustomTextField
				label="Kürzel"
				name="code"
				value={formData.code || ""}
				onChange={handleInputChange}
				size="small"
				sx={{
					maxWidth: "33%",
				}}
				required
				validationError={formErrors?.code}
			/>
			<ClientNameAndAddressSection
				formData={formData}
				formErrors={formErrors}
				onChange={handleInputChange}
				onCheckboxChange={handleCheckboxChange}
				onValueChange={(args) => {
					handleValueChange(args.name, args.value);
				}}
			/>
			<FormSectionWithTitle title="Weiteres">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<TextField
						label="Externe Referenz"
						name="external_reference"
						value={formData.external_reference || ""}
						onChange={handleInputChange}
						variant="outlined"
						size="small"
						fullWidth
					/>
					<LanguageSelect
						value={formData.language || "de"}
						onChange={(value) =>
							handleValueChange("language", value)
						}
					/>
					<TextField
						label="Kurznotiz"
						name="note"
						value={formData.note || ""}
						onChange={handleInputChange}
						variant="outlined"
						size="small"
						fullWidth
					/>
				</div>
			</FormSectionWithTitle>
			<FormSectionWithTitle title="Verrechnung 1">
				<InvoiceSettingsAGroup
					formData={formData}
					formErrors={formErrors}
					onCheckboxChange={handleCheckboxChange}
				/>
			</FormSectionWithTitle>
			<FormSectionWithTitle title="Verrechnung 2">
				<InvoiceSettingsBGroup
					formData={formData}
					formErrors={formErrors}
					onChange={handleInputChange}
					onCheckboxChange={handleCheckboxChange}
					onValueChange={(args) =>
						handleValueChange(args.name, args.value)
					}
				/>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};
