import { StateCreator } from "zustand";
import { State } from "./types";
import { initialState } from "./utils";
import { supabase } from "@/lib/supabase";
import { Logger } from "@/lib/logger/Logger";
import { usePatientStore } from "../Patient";

interface Actions {
	fetchSearchResults: (query: string) => Promise<void>;
	fetchSearchResultsClients: (query: string) => Promise<void>;
	fetchSearchResultsPatients: (query: string) => Promise<void>;
	fetchSearchResultsJobs: (query: string) => Promise<void>;
}

export type SearchSlice = State & Actions;

export const createSearchStore: StateCreator<SearchSlice> = (set, get) => ({
	...initialState,

	fetchSearchResults: async (query) => {
		await Promise.all([
			get().fetchSearchResultsClients(query),
			get().fetchSearchResultsPatients(query),
			get().fetchSearchResultsJobs(query),
		]);
	},

	fetchSearchResultsClients: async (query: string) => {
		set({ loadingClients: true });
		const { data, error } = await supabase.rpc("search_clients", {
			query,
		});
		if (error) {
			Logger.log(error);
		} else {
			set({
				searchResultsClients: data || [],
			});
		}
		set({ loadingClients: false });
	},

	fetchSearchResultsPatients: async (query: string) => {
		set({ loadingPatients: true });
		const patientResults = await usePatientStore
			.getState()
			.fetchPatientSearchResults(query);

		if (patientResults) {
			set({
				searchResultsPatients: patientResults,
			});
		}
		set({ loadingPatients: false });
	},

	fetchSearchResultsJobs: async (query: string) => {
		set({ loadingJobs: true });
		const { data, error } = await supabase.rpc("search_jobs", {
			query,
		});
		if (error) {
			Logger.log(error);
		} else {
			set({
				searchResultsJobs: data,
			});
		}
		set({ loadingJobs: false });
	},
});
