import { JobRequestEntityType } from "@/lib/supabase/supabaseTypes";
import { getStatus } from "./utils";

export const ActivityJobRequestStatusLink = ({
	jobRequest,
}: {
	jobRequest: JobRequestEntityType[];
}) => {
	const accepted = jobRequest?.[0].accepted !== null;
	const rejected = jobRequest?.[0].rejected !== null;
	const job = JSON.parse(jobRequest?.[0]?.request as string);

	const status = getStatus(accepted, rejected);
	return (
		<div
			style={{
				color: status.color,
			}}
		>
			{job.title} {status.text}
		</div>
	);
};
