import {
	ClientEntityType,
	GuarantorEntityType,
	OrganizationEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfLetterhead } from "../pdf-template-components/pdf-letterhead.component";
import { PdfLayout } from "../pdf-template-components/pdf-layout.component";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import "../fonts.css";
import { PdfRecipientAddress } from "../pdf-template-components/pdf-recipient-address.component";
import { PdfTitleAndDate } from "../pdf-template-components/pdf-title-and-date.component";
import { PaymentSlipDataInput } from "../pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { QrPaymentSlip } from "../pdf-template-components/qr-payment-slip/qr-payment-slip.component";
import { calculateSammelrechnungRowsAndTotals } from "../../lib/utils/calculate";
import { PdfTemplateType } from "../document-template.types";
import { PdfTableTotalsSection } from "../pdf-template-components/pdf-table-totals-section.component";
import { PdfFooter } from "../pdf-template-components/pdf-footer.component";
import { formatDate } from "../../lib/utils/utils-functions";

export interface SammelrechnungTemplateProps {
	recipient: ClientEntityType | GuarantorEntityType;
	sammelrechnungCalculationResult: ReturnType<
		typeof calculateSammelrechnungRowsAndTotals
	>;
	organization: OrganizationEntityType | null;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	paymentSlipData: PaymentSlipDataInput;
	currentDate: string;
}

export const SammelrechnungTemplate: React.FC<SammelrechnungTemplateProps> = ({
	recipient,
	organization,
	sammelrechnungCalculationResult,
	pdfTemplateConfiguration,
	paymentSlipData,
	currentDate,
}) => {
	if (
		!organization ||
		!recipient ||
		!pdfTemplateConfiguration ||
		!sammelrechnungCalculationResult ||
		!paymentSlipData
	) {
		return null;
	}
	const { rows: tableRows } = sammelrechnungCalculationResult;

	// If all rows have the same client name, don't show client names
	const showClientNames = !tableRows.every(
		(row, _, arr) => row.clientName === arr[0].clientName
	);
	const showPatientName =
		(recipient as ClientEntityType)?.is_inv_w_pat_data ?? false;
	const showJobTitles =
		(recipient as ClientEntityType)?.is_inv_w_job_title ?? false;

	let numberOfColumns = 7;
	const jobCodeWidth = 13;
	const dateWidth = 13;
	const totalColumnWidth = 12;
	let jobTitleColumnWidth = 20;
	let patientNameColumnWidth = 20;
	let clientNameColumnWidth = 20;
	const extraSpace = 2;
	let spaceToDistribute = 0;

	if (!showPatientName) {
		numberOfColumns -= 1;
		spaceToDistribute += patientNameColumnWidth;
	}
	if (!showClientNames) {
		numberOfColumns -= 1;
		spaceToDistribute += clientNameColumnWidth;
	}
	if (!showJobTitles) {
		numberOfColumns -= 1;
		spaceToDistribute += jobTitleColumnWidth;
	}

	if (spaceToDistribute > 0) {
		if (showJobTitles) {
			jobTitleColumnWidth += spaceToDistribute / 2;
			spaceToDistribute -= spaceToDistribute / 2;
		}
		if (showClientNames) {
			clientNameColumnWidth += spaceToDistribute / 2;
			spaceToDistribute -= spaceToDistribute / 2;
		}
		if (showPatientName) {
			patientNameColumnWidth += spaceToDistribute;
		}
	}

	const sortedRows = tableRows.sort((a, b) => {
		if (showPatientName) {
			return a.patientName.localeCompare(b.patientName);
		} else {
			return a.jobCode.localeCompare(b.jobCode);
		}
	});

	const fontSize = {
		sm: "6pt",
		md: "7pt",
		lg: "9pt",
	};

	return (
		<PdfLayout pdfTemplateConfiguration={pdfTemplateConfiguration}>
			<PdfLetterhead
				organization={organization}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				pdfType={PdfTemplateType.SAMMELRECHNUNG}
			/>
			<PdfRecipientAddress
				recipient={recipient}
				pdfTemplateConfiguration={pdfTemplateConfiguration}
				template={PdfTemplateType.SAMMELRECHNUNG}
			/>
			<div
				aria-label="Dokumenteninhalt"
				style={{
					paddingTop: "5mm",
					paddingLeft: "5mm",
					paddingRight: "10mm",
				}}
			>
				<PdfTitleAndDate
					generalConfig={pdfTemplateConfiguration.general}
					title="Sammelrechnung"
					// As a Sammelrechnung is multiple jobs, we use the current date (i.e. when the PDF is generated) as the date
					date={currentDate}
				/>
				<div
					aria-label="Zusatzinformationen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<table>
						<thead>
							<tr>
								<td
									style={{
										paddingRight: "5mm",
									}}
								>
									Rg.-Nummer
								</td>
								<td>{paymentSlipData.invoiceNumber}</td>
							</tr>
						</thead>
					</table>
				</div>
				<div
					aria-label="Tabelle mit allen Aufträgen"
					style={{
						paddingTop: "5mm",
						fontSize: "10pt",
					}}
				>
					<table
						style={{
							width: "100%",
							borderCollapse: "collapse",
							fontSize:
								fontSize[
									pdfTemplateConfiguration.general.size ??
										"lg"
								],
						}}
					>
						<thead>
							<tr
								style={{
									backgroundColor: "lightgrey",
									borderBottom: "1px solid black",
								}}
							>
								<th
									style={{
										textAlign: "left",
										width: `${jobCodeWidth}%`,
									}}
								>
									Auftrag
								</th>
								<th
									style={{
										textAlign: "left",
										width: `${dateWidth}%`,
									}}
								>
									Datum
								</th>
								{showClientNames && (
									<th
										style={{
											textAlign: "left",
											width: `${clientNameColumnWidth}%`,
										}}
									>
										Auftraggeber
									</th>
								)}
								{showPatientName && (
									<th
										style={{
											textAlign: "left",
											width: `${patientNameColumnWidth}%`,
										}}
									>
										Patient
									</th>
								)}
								{showJobTitles && (
									<th
										style={{
											textAlign: "left",
											width: `${jobTitleColumnWidth}%`,
										}}
									>
										Titel
									</th>
								)}
								{extraSpace > 0 && (
									<td style={{ width: `${extraSpace}mm` }} />
								)}
								<th
									style={{
										textAlign: "right",
										width: `${totalColumnWidth}%`,
									}}
								>
									Betrag
								</th>
							</tr>
						</thead>
						<tbody>
							{sortedRows.map((row, i) => (
								<tr key={row.jobCode + "-" + i}>
									<td
										style={{
											textAlign: "left",
										}}
									>
										{row.jobCode}
									</td>
									<td
										style={{
											textAlign: "left",
										}}
									>
										{formatDate(row.jobDocumentDate)}
									</td>
									{showClientNames && (
										<td
											style={{
												textAlign: "left",
											}}
										>
											{row.clientName}
										</td>
									)}
									{showPatientName && (
										<td
											style={{
												textAlign: "left",
											}}
										>
											{row.patientName}
										</td>
									)}
									{showJobTitles && (
										<td
											style={{
												textAlign: "left",
											}}
										>
											{row.jobTitle}
										</td>
									)}
									{extraSpace > 0 && (
										<td
											style={{ width: `${extraSpace}mm` }}
										/>
									)}
									<td
										style={{
											textAlign: "right",
										}}
									>
										{row.jobTotals.total.text}
									</td>
								</tr>
							))}
							<PdfTableTotalsSection
								totals={{ ...sammelrechnungCalculationResult }}
								numberOfColumns={numberOfColumns}
								numberOfAdditionalColumns={0}
								pdfTemplateConfiguration={
									pdfTemplateConfiguration
								}
								organization={organization}
								monthlyDiscount={
									sammelrechnungCalculationResult
										.monthlyDiscount.text
								}
							/>
						</tbody>
					</table>
				</div>
				<PdfFooter
					templateType={PdfTemplateType.SAMMELRECHNUNG}
					pdfTemplateConfiguration={pdfTemplateConfiguration}
				/>
				<QrPaymentSlip
					pdfTemplateConfiguration={pdfTemplateConfiguration}
					paymentSlipData={paymentSlipData}
				/>
			</div>
		</PdfLayout>
	);
};
