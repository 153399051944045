import RichTextEditor from "@mantine/rte";
import { Button } from "@mui/material";
import { NumberInput } from "@/components/src/inputs/number-input.component";
import { useState } from "react";

export const PDFRtf: React.FC<{
	value?: {
		text: string;
		width?: number;
	};
	controls: string[][];
	onSave: (val: string, width: number) => void;
}> = ({ value: rawValue, controls, onSave }) => {
	const [text, setText] = useState(rawValue?.text);
	const [width, setWidth] = useState(rawValue?.width);
	return (
		<div className="flex w-[350px] gap-2 flex-col items-end">
			<RichTextEditor
				style={{
					width: "100%",
					maxWidth: "350px",
					marginBottom: "24px",
					overflowX: "hidden",
					whiteSpace: "pre",
				}}
				// @ts-expect-error Controls
				controls={controls}
				value={text}
				onChange={setText}
			/>

			<div className="w-full flex flex-col items-start">
				<label
					htmlFor={"width"}
					className="text-gray-600 text-base mb-2"
				>
					Breite
				</label>
				<NumberInput
					id="width"
					label="Breite"
					placeholder="Breite"
					className="h-8 "
					value={width}
					onChange={(_, val) => {
						setWidth(val as number);
					}}
				/>
			</div>
			<Button
				onClick={() => {
					onSave(text ?? "", width ?? 0);
				}}
				disabled={text === rawValue?.text && width == rawValue?.width}
			>
				Speichern
			</Button>
		</div>
	);
};
