import { Alert, FormControl } from "@mui/material";
import { useForm } from "../../../../hooks/useForm/useForm";
import { FormProps } from "../../../form-types";
import { Database } from "../../../../../../lib/supabase";
import { CustomSelect } from "../../../../components/custom-select.component";
import { patientFormTexts } from "../patient-form-texts";
import { TitleAndNameGroup } from "../../../form-input-components/groups/title-and-name.group";
import {
	PatientEntityType,
	SupabaseTableEnum,
} from "../../../../../../lib/supabase/supabaseTypes";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";
import { FormSectionWithSaveButton } from "../../../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { FormSectionWithTitle } from "../../../../components/form-section-with-title/form-section-with-title.component";
import { useCustomDialogContext } from "@/dentlab/src/components/dialog-components/custom-dialog/custom-dialog.component";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { CustomDatePicker } from "@/dentlab/src/components/custom-date-picker/custom-date-picker.component";

interface PatientFormProps extends FormProps {
	patientId?: string;
}

export const PatientFormInDialog: React.FC<PatientFormProps> = ({
	onSave = (patient: PatientEntityType) => {},
	submitRef = null,
	patientId = undefined,
}) => {
	const { setIsPendingChanges } = useCustomDialogContext();
	return (
		<PatientForm
			onSave={onSave}
			submitRef={submitRef}
			setIsPendingChanges={setIsPendingChanges}
			patientId={patientId}
			isRenderedInDialog
		/>
	);
};

export const PatientForm: React.FC<
	PatientFormProps & {
		isRenderedInDialog?: boolean;
		setIsPendingChanges?: (isPendingChanges: boolean) => void;
	}
> = ({
	onSave = (patient: PatientEntityType) => {},
	submitRef = null,
	setIsPendingChanges = () => {},
	patientId = undefined,
	isRenderedInDialog = false,
}) => {
	const supabaseTable = SupabaseTableEnum.PATIENTS;

	const { organizationId, profile } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
		profile: state.profile,
	}));

	const isUpdate = patientId ? true : false;
	const useFormCustomFields = {
		organization_id: organizationId,
	};

	const useFormFetchData = isUpdate ? true : false;
	const useFormFetchId = isUpdate ? patientId : undefined;

	const {
		handleInputChange,
		handleValueChange,
		handleCreate,
		handleUpdate,
		formData,
		formErrors,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["patients"]["Row"]>(
		useFormCustomFields,
		supabaseTable,
		useFormFetchData,
		useFormFetchId,
		"Patient",
		setIsPendingChanges
	);

	const handleUpdateSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleUpdate(event, patientId);
	};

	const handleInsertSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleCreate(event);
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		// Search vector field can only be set by the database
		delete formData.search_vector;
		const { data } = isUpdate
			? await handleUpdateSubmit(event)
			: await handleInsertSubmit(event);
		if (data) {
			const patient = data[0];
			onSave(patient);
		} else {
			if (!formData.code) {
				showNotification({
					message:
						"Möglicherweise konnte kein eindeutiger Code generiert werden. Bitte geben Sie einen Code manuell ein.",
					type: "warning",
				});
			} else {
				showNotification({
					message: "Fehler beim Speichern des Patienten.",
					type: "error",
				});
			}
		}
	};

	const isPrivacyOn = !!profile?.data_privacy_mode;

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			onResetForm={resetFormData}
			isRenderedInDialog={isRenderedInDialog}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<FormSectionWithTitle title="" margin="none">
				<CustomTextField
					label="Code"
					name="code"
					customHelperText="Patientennummer beim Zahnarzt."
					value={formData.code || ""}
					onChange={handleInputChange}
					validationError={formErrors.code}
					autoFocus
					// When creating a new patient and the code is not set, it's created on the database
					// However, when updating a patient, the code is not set on the database and should be required
					required={isUpdate}
				/>
				<div style={{ display: "flex", flexDirection: "column" }}>
					{isPrivacyOn ? (
						<Alert
							severity="info"
							sx={{
								marginTop: "10px",
								marginBottom: "10px",
							}}
						>
							Name-Feld im Datenschutz-Modus nicht verfügbar
						</Alert>
					) : (
						<TitleAndNameGroup
							formData={formData}
							formErrors={formErrors}
							onChange={handleInputChange}
							direction="column"
							onValueChange={(args: any) =>
								handleValueChange(args.name, args.value)
							}
						/>
					)}
					{/* Geschlecht */}
					<FormControl>
						<CustomSelect
							options={patientFormTexts.options.gender}
							inputLabel="Geschlecht"
							// TODO
							onChange={(value) =>
								handleValueChange("gender", value)
							}
							value={formData.gender || ""}
						/>
					</FormControl>
					<FormControl>
						<CustomSelect
							options={patientFormTexts.options.inv_covered_by}
							inputLabel={patientFormTexts.labels.inv_covered_by}
							// TODO
							onChange={(value) =>
								handleValueChange("inv_covered_by", value)
							}
							value={formData.inv_covered_by || ""}
						/>
					</FormControl>
					<CustomTextField
						label="Versicherung"
						name="insurance"
						customHelperText="Name der Krankenkasse oder Versicherung."
						value={formData.insurance || ""}
						onChange={handleInputChange}
						validationError={formErrors.insurance}
					/>
					<div>
						<div className="text-xs text-gray-500">
							Geburtsdatum
						</div>
						<CustomDatePicker
							value={
								formData.birth_date
									? new Date(formData.birth_date)
									: null
							}
							onChange={(e) =>
								handleValueChange("birth_date", e.value)
							}
							size="medium"
						/>
					</div>
					{/* TODO: DW-334 CustomFormattedTextField und AHV-Nummer Input */}
					{isPrivacyOn ? (
						<Alert
							severity="info"
							sx={{
								marginTop: "10px",
								marginBottom: "10px",
							}}
						>
							AHV-Nummer im Datenschutz-Modus nicht verfügbar
						</Alert>
					) : (
						<CustomTextField
							label="AHV-Nummer"
							name="ahv_number"
							value={formData.ahv_number || ""}
							onChange={handleInputChange}
							validationError={formErrors.ahv_number}
						/>
					)}
				</div>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};
