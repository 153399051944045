import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	InputLabel,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import {
	PdfTemplateConfiguration,
	PdfTemplateConfigurationContextProps,
	usePdfTemplateConfigurationContext,
} from "../../context/PdfTemplateConfigurationContext";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import { CustomTextFieldWithSaveButton } from "../../forms/form-input-components/custom-input-components/custom-text-field-with-save-button.component";
import { Alignment } from "../../pdf-templates/pdf-templates/types";
import { PDFRtf } from "./pdf-rtf.component";

// Override pdfTemplateConfiguration: PdfTemplateConfiguration | undefined; to not be undefined
interface SingleConfigurationProps
	extends PdfTemplateConfigurationContextProps {
	pdfTemplateConfiguration: PdfTemplateConfiguration;
}

export const PdfTemplateSingleConfigurations: React.FC<{
	pdfTemplateType: PdfTemplateType;
}> = ({ pdfTemplateType }) => {
	// Pass the entire context as props
	const props: PdfTemplateConfigurationContextProps =
		usePdfTemplateConfigurationContext();
	if (!props.pdfTemplateConfiguration) {
		return null;
	}

	switch (pdfTemplateType) {
		case PdfTemplateType.KOSTENVORANSCHLAG:
			return (
				<KostenvoranschlagSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.LIEFERSCHEIN:
			return (
				<LieferscheinSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.GUTSCHRIFT:
			return (
				<GutschriftSettings {...(props as SingleConfigurationProps)} />
			);
		case PdfTemplateType.RECHNUNG:
			return (
				<RechnungSettings {...(props as SingleConfigurationProps)} />
			);
		case PdfTemplateType.SAMMELRECHNUNG:
			return (
				<SammelrechnungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.KARTENZAHLUNGSQUITTUNG:
			return (
				<KartenzahlungsquittungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.BARZAHLUNGSQUITTUNG:
			return (
				<BarzahlungsquittungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.AKONTORECHNUNG:
			return (
				<AkontorechnungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.ERSTE_MAHNUNG:
			return (
				<ErsteMahnungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.FOLGEMAHNUNG:
			return (
				<FolgemahnungSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		case PdfTemplateType.AUFTRAGSBLATT:
			return (
				<AuftragsblattSettings
					{...(props as SingleConfigurationProps)}
				/>
			);
		default:
			return null;
	}
};

export const KostenvoranschlagSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={
								props.pdfTemplateConfiguration[
									PdfTemplateType.KOSTENVORANSCHLAG
								].showSignature
							}
							onChange={(e) => {
								props.updatePdfTemplateConfiguration(
									PdfTemplateType.KOSTENVORANSCHLAG,
									{
										showSignature: e.target.checked,
									}
								);
							}}
						/>
					}
					label="Unterschrift anzeigen"
				/>
			</FormGroup>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.KOSTENVORANSCHLAG}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.KOSTENVORANSCHLAG}
				{...props}
			/>
		</div>
	);
};

export const LieferscheinSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.LIEFERSCHEIN}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.LIEFERSCHEIN}
				{...props}
			/>
		</div>
	);
};

export const GutschriftSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.GUTSCHRIFT}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.GUTSCHRIFT}
				{...props}
			/>
		</div>
	);
};

export const RechnungSettings: React.FC<SingleConfigurationProps> = (props) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.RECHNUNG}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.RECHNUNG}
				{...props}
			/>
			<CustomTextFieldWithSaveButton
				label="Text oberhalb des Einzahlungsscheins"
				value={
					props.pdfTemplateConfiguration[PdfTemplateType.RECHNUNG]
						.textAbovePaymentSlip
				}
				validationError={null}
				multiline
				rows={4}
				fullWidth
				onSave={(value) => {
					props.updatePdfTemplateConfiguration(
						PdfTemplateType.RECHNUNG,
						{
							textAbovePaymentSlip: value,
						}
					);
				}}
				onReset={() => {
					props.resetConfigurationColumn(
						PdfTemplateType.RECHNUNG,
						"textAbovePaymentSlip"
					);
				}}
			/>
		</div>
	);
};

export const SammelrechnungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.SAMMELRECHNUNG}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.SAMMELRECHNUNG}
				{...props}
			/>
		</div>
	);
};

export const KartenzahlungsquittungSettings: React.FC<
	SingleConfigurationProps
> = (props) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.KARTENZAHLUNGSQUITTUNG}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.KARTENZAHLUNGSQUITTUNG}
				{...props}
			/>
		</div>
	);
};

export const BarzahlungsquittungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.BARZAHLUNGSQUITTUNG}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.BARZAHLUNGSQUITTUNG}
				{...props}
			/>
		</div>
	);
};

export const AkontorechnungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.AKONTORECHNUNG}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.AKONTORECHNUNG}
				{...props}
			/>
		</div>
	);
};

export const ErsteMahnungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.ERSTE_MAHNUNG}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.ERSTE_MAHNUNG}
				{...props}
			/>
		</div>
	);
};

export const FolgemahnungSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return (
		<div>
			<FooterTextSettings
				pdfTemplateType={PdfTemplateType.FOLGEMAHNUNG}
				{...props}
			/>
			<CommentTextSettings
				pdfTemplateType={PdfTemplateType.FOLGEMAHNUNG}
				{...props}
			/>
		</div>
	);
};

export const AuftragsblattSettings: React.FC<SingleConfigurationProps> = (
	props
) => {
	return <div></div>;
};

const FooterTextSettings: React.FC<
	SingleConfigurationProps & { pdfTemplateType: PdfTemplateType }
> = ({
	pdfTemplateConfiguration,
	updatePdfTemplateConfiguration,
	pdfTemplateType,
}) => {
	return (
		<PDFRtf
			value={pdfTemplateConfiguration[pdfTemplateType].footerText}
			controls={[
				[
					"italic",
					"underline",
					"bold",
					"alignLeft",
					"alignCenter",
					"alignRight",
				],
				["h1", "h2", "h3", "h4", "h5", "h6"],
				["image"],
			]}
			onSave={(val: string, width: number) => {
				updatePdfTemplateConfiguration(pdfTemplateType, {
					footerText: {
						text: val,
						width,
					},
				});
			}}
		/>
	);
};

const CommentTextSettings: React.FC<
	SingleConfigurationProps & { pdfTemplateType: PdfTemplateType }
> = ({
	pdfTemplateConfiguration,
	updatePdfTemplateConfiguration,
	pdfTemplateType,
}) => {
	return (
		<div>
			<div className="flex mb-6 flex-col gap-2">
				<InputLabel id="COmment alignment">
					Kommentar-Ausrichtung
				</InputLabel>

				<ToggleButtonGroup
					color="primary"
					value={pdfTemplateConfiguration?.general.commentAlignment}
					exclusive
					onChange={(_, v) => {
						updatePdfTemplateConfiguration("general", {
							commentAlignment: v as Alignment,
						});
					}}
					aria-label="Platform"
				>
					<ToggleButton value="left">Links</ToggleButton>
					<ToggleButton value="center">Zentriert</ToggleButton>
					<ToggleButton value="right">Rechts</ToggleButton>
				</ToggleButtonGroup>
			</div>

			<PDFRtf
				value={pdfTemplateConfiguration[pdfTemplateType].commentText}
				controls={[
					[
						"italic",
						"underline",
						"bold",
						"alignLeft",
						"alignCenter",
						"alignRight",
					],
					["h1", "h2", "h3", "h4", "h5", "h6"],
					["image"],
				]}
				onSave={(val: string, width: number) => {
					updatePdfTemplateConfiguration(pdfTemplateType, {
						commentText: {
							text: val,
							width,
						},
					});
				}}
			/>
		</div>
	);
};
