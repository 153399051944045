import {
	FileSpreadsheet,
	FilePlus2,
	FileBox,
	FileText,
	FileImage,
	FileCode,
	FileType2,
	File,
} from "lucide-react";
import { FileType } from "./file-icon-utils";

const colorMap = {
	success: "green",
	error: "red",
	warning: "yellow",
	info: "blue",
};

/**
 * Named this "...Component" to avoid conflict with:
 * import { FileIcon } from "lucide-react";
 */
export const FileIconComponent: React.FC<{
	fileType: FileType;
	size?: number;
	color?: "success" | "error" | "warning" | "info" | undefined;
}> = ({ fileType, size = 20, color }) => {
	const colorToUse = color ? colorMap[color] : undefined;
	switch (fileType) {
		case "kv":
			return <FileSpreadsheet size={size} color={colorToUse} />;
		case "ls":
			return <FileBox size={size} color={colorToUse} />;
		case "gs":
			return <FilePlus2 size={size} color={colorToUse} />;
		case "pdf":
			return <FileText size={size} color={colorToUse} />;
		case "img":
			return <FileImage size={size} color={colorToUse} />;
		case "xml":
			return <FileCode size={size} color={colorToUse} />;
		case "txt":
			return <FileType2 size={size} color={colorToUse} />;
		default:
			return <File size={size} color={colorToUse} />;
	}
};
