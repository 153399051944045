import { Avatar } from "@mui/material";
import { useProfileContext } from "../../../lib/contexts/ProfileContext";
import { stringAvatar } from "../lib/utils/avatar-functions";

export const UserAvatarComponent: React.FC = () => {
	const {
		profile: { firstName, lastName },
	} = useProfileContext();
	return (
		<Avatar
			{...stringAvatar(`${firstName} ${lastName}`)}
			variant="rounded"
			sx={{
				height: "30px",
				width: "30px",
				fontSize: "14px",
			}}
			aria-label="user avatar"
		/>
	);
};
