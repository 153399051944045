import { ActivityEntityType } from "@/lib/supabase/supabaseTypes";
import { Description, InsertDriveFile } from "@mui/icons-material";
import Message from "@mui/icons-material/Message";
import { Avatar, Badge, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { stringAvatar } from "@lab/src/lib/utils/avatar-functions";
import { PdfPreview } from "@/dentlab/src/components/file-display-components/file-display.components";
import {
	SharesWithFileInfoWithUrl,
	useStorage,
} from "@/dentlab/src/hooks/useStorage";
import { Logger } from "@/lib/logger/Logger";
import { getDayAndTime } from "@/dentlab/src/lib/utils/utils-functions";
import ArticleIcon from "@mui/icons-material/Article";

const getExtension = (fileName: string) => {
	const extension = fileName.split(".").pop()?.toLowerCase();
	return extension;
};

function isImageFile(fileName: string): boolean {
	const imageExtensions = [
		"jpg",
		"jpeg",
		"png",
		"gif",
		"bmp",
		"webp",
		"tiff",
		"svg",
	];
	const extension = getExtension(fileName);

	return extension ? imageExtensions.includes(extension) : false;
}

export const SharedDocument = ({
	file,
	openThread,
}: {
	file: SharesWithFileInfoWithUrl;
	openThread: (activity: ActivityEntityType | null) => void;
}) => {
	// TODO: re-implement sender name
	/* 	const { sx: sxForAvatar, children: childrenForAvatar } = stringAvatar(
            activity.sender_name ?? ""
        ); */
	const { openFileInNewTab } = useStorage();
	const [fileUrl, setFileUrl] = useState<string | null>(null);

	const { fetchFile } = useStorage();

	useEffect(() => {
		const fn = async () => {
			if (
				isImageFile(file?.file_name ?? "") ||
				getExtension(file?.file_name ?? "") === "pdf" ||
				// @ts-expect-error metadata is a JSON field so type can't be inferred
				file.meta_data?.type == "pdf"
			) {
				const { data, error } = await fetchFile(file);
				if (error) {
					Logger.error(error);
					return;
				}

				setFileUrl(data?.url ?? null);
			}
		};
		fn();
	}, [file]);

	const { sx: sxForAvatar, children: childrenForAvatar } = stringAvatar(
		file.created_by ?? ""
	);

	return (
		<div
			style={{
				width: "240px",
				height: "260px",
				borderRadius: "6px",
				overflow: "hidden",
			}}
			className="border"
		>
			<div
				onClick={() => {
					openFileInNewTab(file?.path_name as string);
				}}
				style={{
					cursor: "pointer",
					background: "rgb(182,182,182)",
					height: "66%",
					overflow: "hidden",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
				}}
				className="border-b"
			>
				<div
					style={{
						scale: "150%",
					}}
				>
					{/*@ts-expect-error metadata is a JSON field so type can't be inferred */}
					{(file.meta_data?.type == "pdf" ||
						getExtension(file?.file_name ?? "") === "pdf") &&
						fileUrl && (
							<PdfPreview file={{ ...file, url: fileUrl }} />
						)}
					{isImageFile(file?.file_name ?? "") && fileUrl && (
						<img
							alt={file?.file_name ?? ""}
							src={fileUrl}
							style={{
								width: "100%",
								height: "100%",
							}}
						/>
					)}
					{!fileUrl && <Description />}
				</div>
				<div
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						margin: "10px",
						padding: "0px 4px",
						height: "20px",
						display: "flex",
						alignItems: "center",
						borderRadius: "5px",
						background: "rgba(182,182,182, 0.7)",
						fontSize: "12px",
						color: "white",
					}}
				>
					{getDayAndTime(file?.created_at)}
				</div>
			</div>
			<div
				style={{
					height: "34%",
					background: "white",
				}}
				className="px-3 py-2.5 flex flex-col gap-1"
			>
				<div
					style={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						width: "100%",
						fontSize: "16px",
					}}
				>
					{file?.file_name}
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							fontSize: "14px",
						}}
					>
						{file?.job_document_id == null ? (
							<InsertDriveFile
								style={{
									color: "#082f49",
								}}
							/>
						) : (
							<ArticleIcon
								style={{
									color: "#1e40af",
								}}
							/>
						)}
					</div>
					<div className="flex items-center gap-1">
						<div>
							<IconButton
								onClick={() => {
									// In case another activity is opened
									openThread({
										id: file?.id,
									} as ActivityEntityType);
								}}
							>
								<Badge
									color="primary"
									badgeContent={file.reply_count}
								>
									<Message />
								</Badge>
							</IconButton>
						</div>
						{/* TODO: Re-implement shared by name */}
						<Tooltip title={`Geteilt von ${childrenForAvatar} `}>
							<Avatar
								sx={{
									...sxForAvatar,
									width: "24px",
									height: "24px",
									fontSize: "12px",
								}}
							>
								{childrenForAvatar}
							</Avatar>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	);
};
