import {
	Alert,
	Badge,
	Box,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Menu,
	Radio,
	RadioGroup,
	Tab,
	Tabs,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PdfTemplateSelect } from "./pdf-template-select.component";
import { clientExample } from "../../constants/clientExample";
import { jobExample } from "../../constants/jobExample";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { Document, Page } from "react-pdf";
import { jobDocumentExample } from "../../constants/jobDocumentExample";
import { usePdfTemplateConfigurationContext } from "../../context/PdfTemplateConfigurationContext";
import { patientExample } from "../../constants/patientExample";
import { useSearchParams } from "react-router-dom";
import { PdfTemplateSingleConfigurations } from "./pdf-template-single-configurations.component";
import { PdfTemplateType } from "../../pdf-templates/document-template.types";
import {
	PaymentSlipDataInput,
	formatPaymentSlipData,
} from "../../pdf-templates/pdf-template-components/qr-payment-slip/qr-payment-helpers";
import { BankAccountSelect } from "../../forms/form-components/bank-account/input-components/bank-account.select";
import {
	BankAccountEntityType,
	ClientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { bankAccountExample } from "../../constants/bankAccountExample";
import { ClientSelect } from "../../forms/form-input-components/selects/client.select";
import DownloadIcon from "@mui/icons-material/Download";
import CachedIcon from "@mui/icons-material/Cached";
import {
	ExtendedJobDocument,
	ParsedJobItemFromView,
	calculateJobTotalsLegacy,
	calculateSammelrechnungRowsAndTotals,
} from "../../lib/utils/calculate";
import { CustomTextFieldWithSaveButton } from "../../forms/form-input-components/custom-input-components/custom-text-field-with-save-button.component";
import { PdfTemplateOptions } from "./pdf-selected-template.config";
import { jobItemExamples } from "../../constants/jobItemExample";
import { useCentralStore } from "../../store/Central";
import { PdfTemplateLogo } from "./pdf-template-logo.component";
import { Logger } from "@/lib/logger/Logger";
import { useStorage } from "../../hooks/useStorage";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../../types/enums";
import { Alignment } from "../../pdf-templates/pdf-templates/types";
import { PDFTemplateConfigurationSettings } from "./pdf-template-configuration-settings.component";
import FeedbackIcon from "@mui/icons-material/Feedback";
import {
	Accordion,
	AccordionItem,
	AccordionTrigger,
	AccordionContent,
} from "../../components/ui/accordion";
import { JobDocumentTypeEnum } from "../job-page/job-document/job-document.types";

export const PdfTemplateConfiguration: React.FC = () => {
	// Persist the selected template via ?template=...
	const [searchParams, setSearchParams] = useSearchParams();
	const { fileUrl } = useStorage();
	const template = searchParams.get("template");

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [selectedBankAccount, setSelectedBankAccount] =
		useState<BankAccountEntityType | null>(null);
	const bankAccount = selectedBankAccount ?? bankAccountExample;

	const { clientsLookup, clients, organization, bankAccountsLookup } =
		useCentralStore((state) => ({
			clientsLookup: state.clientsLookup,
			clients: Object.values(state.clientsLookup),
			organization: state.organization,
			bankAccountsLookup: state.bankAccountsLookup,
		}));
	const [selectedClient, setSelectedClient] =
		useState<ClientEntityType | null>(null);
	const client = selectedClient ?? clientExample;

	useEffect(() => {
		if (Object.keys(bankAccountsLookup).length > 0) {
			setSelectedBankAccount(
				bankAccountsLookup[Object.keys(bankAccountsLookup)[0]]
			);
		}
	}, [bankAccountsLookup]);

	useEffect(() => {
		if (clients.length > 0) {
			setSelectedClient(clients[0]);
		}
	}, []);

	const { pdfTemplateConfiguration, updatePdfTemplateConfiguration } =
		usePdfTemplateConfigurationContext();

	const [selectedPdfTemplate, setSelectedPdfTemplate] =
		useState<PdfTemplateType>(
			(template as PdfTemplateType) || PdfTemplateType.KOSTENVORANSCHLAG
		);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [numPages, setNumPages] = useState<number>(0);
	const [pdfBase64String, setPdfBase64String] = useState<string>("");
	const [isDocumentLoading, setIsDocumentLoading] = useState(true);
	const [isPageLoading, setIsPageLoading] = useState(false);
	const [clientAvatarUrl, setClientAvatarUrl] = useState<string | null>(null);
	const [currentTab, setCurrentTab] = useState(0);

	useEffect(() => {
		const fetchCustomImage = async () => {
			if (!pdfTemplateConfiguration?.general) return;
			const { data } = await supabase.storage
				.from(StorageBucketsEnum.ORG_V1)
				.createSignedUrl(
					pdfTemplateConfiguration?.general.customIconPath ?? "",
					3600,
					{}
				);

			if (data && !pdfTemplateConfiguration?.general.customImgUrl) {
				updatePdfTemplateConfiguration("general", {
					customImgUrl: data.signedUrl,
				});
				// Clear the url when it expires
				setTimeout(() => {
					updatePdfTemplateConfiguration("general", {
						customImgUrl: "",
					});
				}, 3599 * 1000);
			}
		};
		fetchCustomImage();
	}, [pdfTemplateConfiguration?.general]);

	useEffect(() => {
		refreshPreviewPage();
	}, [selectedPdfTemplate, pdfTemplateConfiguration, bankAccount]);

	useEffect(() => {
		setIsPageLoading(true);
		setPageNumber(1);
	}, [selectedPdfTemplate]);

	useEffect(() => {
		const fetchClientAvatarUrl = async () => {
			let clientAvatarPath = client?.avatar_path;
			if (!clientAvatarPath) {
				setClientAvatarUrl(null);
				refreshPreviewPage();
				return;
			}

			//TODO(Eyob): Why is the client.avatar_path already signed?
			// https://dentdesk.atlassian.net/browse/DW-884
			if (clientAvatarPath.startsWith("http")) {
				clientAvatarPath = clientAvatarPath
					?.split("supabase.co/storage/v1/render/image/sign/v1/")[1]
					?.split("?")[0];
			}

			const imageSrc = await fileUrl(clientAvatarPath);

			Logger.info("clientAvatarPath", clientAvatarPath, imageSrc);

			if (!imageSrc.data) {
				setClientAvatarUrl(null);
				refreshPreviewPage();
				return;
			}

			setClientAvatarUrl(imageSrc.data.signedUrl);
		};

		fetchClientAvatarUrl();
	}, [client]);

	useEffect(() => {
		refreshPreviewPage();
	}, [clientAvatarUrl]);

	const handleSelectPdfTemplate = (value: PdfTemplateType) => {
		setIsDocumentLoading(true);
		setSelectedPdfTemplate(value);
		setSearchParams({ ...searchParams, template: value });
	};

	const refreshPreviewPage = () => {
		setIsDocumentLoading(true);
		const pdfElement = document.querySelector(
			".pdf-preview"
		) as HTMLElement;
		drawDOM(pdfElement, {
			paperSize: ["210mm", "297mm"],
			keepTogether: "p",
			scale: 0.75,
		})
			.then((group) => {
				return exportPDF(group, {});
			})
			.then((dataUri) => {
				const base64String = dataUri.split(";base64,")[1];
				setPdfBase64String(base64String);
			});
	};

	const paymentSlipData: PaymentSlipDataInput = {
		bankAccount: bankAccount,
		recipient: client,
		amount: "1234.56",
		currency: "CHF",
		invoiceNumber: "000001",
	};

	const errorsAlerts: {
		severity: "warning" | "error";
		message: string;
	}[] = [];

	if (!client) {
		errorsAlerts.push({
			severity: "warning",
			message: "Kein Beispiel-Auftraggeber ausgewählt",
		});
	}
	if (!bankAccount) {
		errorsAlerts.push({
			severity: "warning",
			message: "Kein Beispiel-Bankkonto ausgewählt",
		});
	}
	if (!organization?.name) {
		errorsAlerts.push({
			severity: "warning",
			message: "Firmenname des Labors fehlt in den Einstellungen",
		});
	}
	if (!organization?.street) {
		errorsAlerts.push({
			severity: "warning",
			message: "Strasse fehlt in der Laboradresse in den Einstellungen",
		});
	}
	if (!organization?.city) {
		errorsAlerts.push({
			severity: "warning",
			message: "Ort fehlt in der Laboradresse in den Einstellungen",
		});
	}
	if (!organization?.mwst_number) {
		errorsAlerts.push({
			severity: "warning",
			message: "MwSt.-Nummer fehlt in den Einstellungen",
		});
	}
	if (!organization?.mwst_percentage) {
		errorsAlerts.push({
			severity: "warning",
			message: "MwSt.-Satz fehlt in den Einstellungen",
		});
	}

	const { error: paymentSlipError } = formatPaymentSlipData(paymentSlipData);
	if (paymentSlipError) {
		errorsAlerts.push({ severity: "error", message: paymentSlipError });
	}

	if (!organization) {
		return <div>Lädt...</div>;
	}
	if (!client) {
		return <div>Lädt...</div>;
	}
	const jobTotals = calculateJobTotalsLegacy({
		job: jobExample,
		jobDocument: jobDocumentExample,
		jobItems: jobItemExamples,
		organization,
		client,
	});
	const extendedJobDocumentsExample: ExtendedJobDocument[] = Array.from(
		{ length: 3 },
		(_, i) => i
	).map(() => {
		return {
			job_client_id: clientExample.id,
			job_code: jobExample.code,
			job_document_date: jobDocumentExample.date,
			job_document_discount_material:
				jobDocumentExample.discount_material,
			job_document_discount_work: jobDocumentExample.discount_work,
			job_document_id: jobDocumentExample.id,
			job_items: jobItemExamples as ParsedJobItemFromView[],
			job_title: jobExample.title,
			organization_id: organization.id,
			patient_code: patientExample.code,
			patient_first_name: patientExample.first_name,
			patient_last_name: patientExample.last_name,
			patient_title: patientExample.title,
			job_document_type: JobDocumentTypeEnum.DeliveryNote,
		};
	});
	const sammelrechnungCalculationResult =
		calculateSammelrechnungRowsAndTotals(
			extendedJobDocumentsExample,
			organization,
			clientExample,
			clientsLookup
		);

	const goBackPage = () => {
		if (pageNumber > 1) {
			setIsPageLoading(true);
			setPageNumber((prev) => prev - 1);
		}
	};

	const goForwardPage = () => {
		if (pageNumber < numPages) setIsPageLoading(true);
		setPageNumber((prev) => Math.min(prev + 1, numPages));
	};

	const handlePageHasFinishedLoading = () => {
		setTimeout(() => {
			setIsPageLoading(false);
		}, 200);
	};

	const handleDocumentHasFinishedLoading = () => {
		setTimeout(() => {
			setIsDocumentLoading(false);
		}, 200);
	};

	return (
		<div className="flex flex-col">
			<div className="flex flex-row border border-gray-200 p-4 gap-2 items-center w-full">
				<PdfTemplateSelect
					value={selectedPdfTemplate}
					onChange={handleSelectPdfTemplate}
				/>
				<div>
					<IconButton
						onClick={() => {
							const linkSource = `data:application/pdf;base64,${pdfBase64String}`;
							const downloadLink = document.createElement("a");
							const fileName = "Kostenvoranschlag.pdf";
							downloadLink.href = linkSource;
							downloadLink.download = fileName;
							downloadLink.click();
						}}
					>
						<DownloadIcon />
					</IconButton>
					<IconButton onClick={refreshPreviewPage}>
						<CachedIcon />
					</IconButton>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					height: "100%",
					width: "100%",
					justifyContent: "center",
					gap: "2%",
				}}
			>
				<div
					style={{
						padding: "40px 0px 40px 0px",
						width: "40%",
						minWidth: "300px",
						maxWidth: "900px",
						display: "flex",
						flexDirection: "column",
						gap: "20px",
					}}
				>
					{/* PDF TEMPLATE SELECT */}
					{/* ACCORDION */}
					<div>
						<div className="w-full overflow-x-auto">
							<Tabs
								value={currentTab}
								onChange={(e, v) => {
									setCurrentTab(v);
								}}
								aria-label="basic tabs example"
								className="w-[760px]"
							>
								<Tab label="Beispieldaten" />
								<Tab label="Allgemein" />
								<Tab label="Aktuelles Dokument" />
								{errorsAlerts.length > 0 && (
									<div className="flex justify-end items-center">
										<IconButton onClick={handleClick}>
											<Badge
												badgeContent={
													errorsAlerts.length
												}
												color="warning"
											>
												<FeedbackIcon />
											</Badge>
										</IconButton>
										<Menu
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
										>
											{errorsAlerts.map(
												(alert, index) => (
													<Alert
														severity={
															alert.severity
														}
														key={index}
													>
														{alert.message}
													</Alert>
												)
											)}
										</Menu>
									</div>
								)}
							</Tabs>
						</div>
						<CustomTabPanel value={currentTab} index={0}>
							<>
								<BankAccountSelect
									onChange={(value) =>
										setSelectedBankAccount(
											bankAccountsLookup[value]
										)
									}
									value={bankAccount?.id.toString()}
								/>
								<ClientSelect
									value={client?.id.toString()}
									onChange={(value) =>
										setSelectedClient(clientsLookup[value])
									}
								/>
							</>
						</CustomTabPanel>
						<CustomTabPanel value={currentTab} index={1}>
							<CustomTextFieldWithSaveButton
								label="E-Mail Adresse für Kopfzeile"
								value={pdfTemplateConfiguration?.general?.email}
								onSave={(value) => {
									updatePdfTemplateConfiguration("general", {
										email: value,
									});
								}}
								validationError={null}
								fullWidth
							/>
							<Accordion
								type="single"
								collapsible
								className="w-full"
							>
								<PDFTemplateConfigurationSettings
									template={"general"}
								/>
								<AccordionItem value="item-6">
									<AccordionTrigger>Layout</AccordionTrigger>

									<AccordionContent>
										<CustomTextFieldWithSaveButton
											label="Rand oben in mm"
											value={
												pdfTemplateConfiguration
													?.general?.marginTop
											}
											onSave={(value) => {
												updatePdfTemplateConfiguration(
													"general",
													{
														marginTop:
															parseInt(value),
													}
												);
											}}
											validationError={null}
											fullWidth
										/>
										<CustomTextFieldWithSaveButton
											label="Rand links in mm"
											value={
												pdfTemplateConfiguration
													?.general?.marginLeft
											}
											onSave={(value) => {
												updatePdfTemplateConfiguration(
													"general",
													{
														marginLeft:
															parseInt(value),
													}
												);
											}}
											validationError={null}
											fullWidth
										/>
										<CustomTextFieldWithSaveButton
											label="Rand rechts in mm"
											value={
												pdfTemplateConfiguration
													?.general?.marginRight
											}
											onSave={(value) => {
												updatePdfTemplateConfiguration(
													"general",
													{
														marginRight:
															parseInt(value),
													}
												);
											}}
											validationError={null}
											fullWidth
										/>
										<CustomTextFieldWithSaveButton
											label="Rand unten in mm"
											value={
												pdfTemplateConfiguration
													?.general?.marginBottom
											}
											onSave={(value) => {
												updatePdfTemplateConfiguration(
													"general",
													{
														marginBottom:
															parseInt(value),
													}
												);
											}}
											validationError={null}
											fullWidth
										/>
										<div>
											<FormControl>
												<FormLabel id="demo-radio-buttons-group-label">
													<div className="font-bold text-lg">
														Dichte
													</div>
												</FormLabel>
												<RadioGroup
													aria-labelledby="demo-radio-buttons-group-label"
													name="radio-buttons-group"
													value={
														pdfTemplateConfiguration
															?.general.size ??
														"lg"
													}
													sx={{
														display: "flex",
													}}
													onChange={(e, v) => {
														updatePdfTemplateConfiguration(
															"general",
															{
																size: v as
																	| "sm"
																	| "md"
																	| "lg",
															}
														);
													}}
												>
													<FormControlLabel
														value="sm"
														control={
															<Radio
																checked={
																	pdfTemplateConfiguration
																		?.general
																		.size ==
																	"sm"
																}
																size="small"
															/>
														}
														label="Klein"
													/>
													<FormControlLabel
														value="md"
														control={
															<Radio
																checked={
																	pdfTemplateConfiguration
																		?.general
																		.size ==
																	"md"
																}
															/>
														}
														label="Mittel"
													/>
													<FormControlLabel
														value="lg"
														control={
															<Radio
																checked={
																	pdfTemplateConfiguration
																		?.general
																		.size ==
																	"lg"
																}
																sx={{
																	"& .MuiSvgIcon-root":
																		{
																			fontSize: 30,
																		},
																}}
															/>
														}
														label="Groß"
													/>
												</RadioGroup>
											</FormControl>
										</div>
									</AccordionContent>
								</AccordionItem>
								<AccordionItem value="item-5">
									<AccordionTrigger>
										Firmenlogo
									</AccordionTrigger>
									<AccordionContent>
										<PdfTemplateLogo
											pdfTemplateConfiguration={
												pdfTemplateConfiguration
											}
										/>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CustomTabPanel>
						<CustomTabPanel value={currentTab} index={2}>
							<FormControlLabel
								className="mb-1"
								control={
									<Checkbox
										disabled={
											!pdfTemplateConfiguration?.general
												.logoImgurl
										}
										checked={
											pdfTemplateConfiguration?.[
												selectedPdfTemplate
											].showLogo
										}
										onChange={(e) => {
											updatePdfTemplateConfiguration(
												selectedPdfTemplate,
												{
													showLogo: e.target.checked,
												}
											);
										}}
									/>
								}
								label="Logos anzeigen"
							/>
							<Accordion
								type="single"
								collapsible
								className="w-full"
							>
								<PDFTemplateConfigurationSettings
									template={selectedPdfTemplate}
								/>
								<AccordionItem value="item-7">
									<AccordionTrigger id="Footer alignment">
										Fusszeile
									</AccordionTrigger>
									<AccordionContent>
										<div className="flex flex-col gap-2">
											<ToggleButtonGroup
												color="primary"
												value={
													pdfTemplateConfiguration
														?.general
														.footerAlignment
												}
												exclusive
												className="mb-4"
												onChange={(e, v) => {
													updatePdfTemplateConfiguration(
														"general",
														{
															footerAlignment:
																v as Alignment,
														}
													);
												}}
												aria-label="Platform"
											>
												<ToggleButton value="left">
													Links
												</ToggleButton>
												<ToggleButton value="center">
													Zentriert
												</ToggleButton>
												<ToggleButton value="right">
													Rechts
												</ToggleButton>
											</ToggleButtonGroup>
										</div>

										<PdfTemplateSingleConfigurations
											pdfTemplateType={
												selectedPdfTemplate
											}
										/>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						</CustomTabPanel>
					</div>
				</div>
				<div
					style={{
						height: "100%",
						padding: "40px",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<div
						style={{
							boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.3)",
							position: "relative",
						}}
					>
						<Document
							onLoadSuccess={(n) => {
								setNumPages(n._pdfInfo?.numPages);
								handleDocumentHasFinishedLoading();
							}}
							file={`data:application/pdf;base64,${pdfBase64String}`}
							loading={<PdfPreviewLoadingPlaceholder />}
							error={"PDF konnte nicht geladen werden"}
						>
							<Page
								onLoadSuccess={handlePageHasFinishedLoading}
								pageNumber={pageNumber}
								width={500}
								loading={<PdfPreviewLoadingPlaceholder />}
								error={"Seite konnte nicht geladen werden"}
							/>
						</Document>
						<PdfPreviewLoadingOverlay
							isLoading={isDocumentLoading || isPageLoading}
						/>
					</div>
					<div
						style={{
							marginTop: 24,
							display: "flex",
							justifyContent: "center",
							width: "full",
							alignItems: "center",
						}}
					>
						<IconButton
							disabled={pageNumber <= 1}
							onClick={goBackPage}
						>
							<IconChevronLeft />
						</IconButton>
						{pageNumber}
						<IconButton
							disabled={pageNumber >= numPages}
							onClick={goForwardPage}
						>
							<IconChevronRight />
						</IconButton>
					</div>
				</div>
				<div
					style={{
						position: "relative",
					}}
				>
					<div
						style={{
							position: "absolute",
							left: "-10000px",
							top: "-10000px",
						}}
					>
						<div className="pdf-preview">
							<PdfTemplateOptions
								client={client}
								jobExample={jobExample}
								jobItems={jobItemExamples}
								jobDocumentExample={jobDocumentExample}
								organization={organization}
								pdfTemplateConfiguration={
									pdfTemplateConfiguration
								}
								patientExample={patientExample}
								jobTotals={jobTotals}
								paymentSlipData={paymentSlipData}
								sammelrechnungCalculationResult={
									sammelrechnungCalculationResult
								}
								selectedPdfTemplate={selectedPdfTemplate}
								clientAvatarUrl={clientAvatarUrl ?? undefined}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const PdfPreviewLoadingPlaceholder: React.FC = () => {
	return (
		<div
			style={{
				top: 0,
				zIndex: 101,
				backgroundColor: "white",
				width: 500,
				height: (500 / 210) * 297,
			}}
		></div>
	);
};

/** We use this in combination with PdfPreviewLoadingPlaceholder to override the loading feedback react-pdf provides */
const PdfPreviewLoadingOverlay: React.FC<{
	isLoading: boolean;
}> = ({ isLoading }) => {
	return (
		<div
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				backgroundColor: "white",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				zIndex: isLoading ? 101 : -1,
			}}
		>
			<CircularProgress />
		</div>
	);
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			className="overflow-y-auto"
			{...other}
		>
			{value === index && <div style={{ padding: 3 }}>{children}</div>}
		</div>
	);
}
