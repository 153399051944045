import { useEffect, useState } from "react";
import {
	ClientEntityType,
	GuarantorEntityType,
} from "../../../../lib/supabase/supabaseTypes";
import {
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { JobDocumentTypeEnum } from "../job-page/job-document/job-document.types";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { usePatientStore } from "../../store/Patient";
import { useNavigate } from "react-router-dom";
import { useExportActions } from "../../hooks/actions/useExportActions";
import { useCentralStore } from "../../store/Central";
import { supabase } from "@/lib/supabase";
import { Logger } from "@/lib/logger/Logger";
import { useAccountingStore } from "../../store/Accounting";
import {
	calculateSammelrechnungRowsAndTotals,
	roundToRappen,
	ExtendedJobDocument,
} from "../../lib/utils/calculate";
import { showNotification } from "../../store/Central/selectors";
import { RecipientTypeEnum } from "./types";
import { getBankAccount } from "../../hooks/actions/get-bank-account/get-bank-account";
import { CompletedJobDocument } from "./monthly-invoices.page";

const isInDateRange = (
	jobDocument: CompletedJobDocument,
	dateRange: SelectionRange
) => {
	const documentDate = new Date(jobDocument.date as string);
	const startDate = new Date(dateRange.start as Date);
	const endDate = new Date(dateRange.end as Date);

	// Set the time to the start of the day (00:00:00.000)
	documentDate.setHours(0, 0, 0, 0);
	startDate.setHours(0, 0, 0, 0);
	endDate.setHours(0, 0, 0, 0);

	// Check if the modified date is within the date range
	return documentDate >= startDate && documentDate <= endDate;
};

export const MonthlyInvoicesCard: React.FC<{
	recipient: ClientEntityType | GuarantorEntityType;
	dateRange: SelectionRange;
	onManualSelect: () => void;
	onFetchData: () => void;
	rows: CompletedJobDocument[];
	recipientType: RecipientTypeEnum;
}> = ({
	recipient,
	dateRange,
	onManualSelect,
	onFetchData,
	rows,
	recipientType,
}) => {
	const { organization, bankAccountsLookup, clientsLookup } = useCentralStore(
		(state) => ({
			organization: state.organization,
			bankAccountsLookup: state.bankAccountsLookup,
			clientsLookup: state.clientsLookup,
		})
	);
	const { patientName, fetchPatientsByIds, patientsLookup } = usePatientStore(
		(state) => ({
			patientName: state.patientName,
			fetchPatientsByIds: state.fetchPatientsByIds,
			patientsLookup: state.patientsLookup,
		})
	);

	useEffect(() => {
		fetchPatientsByIds(
			rows
				.filter((jobDocument) => jobDocument?.job_patient_id)
				.map((jobDocument) => jobDocument.job_patient_id as string)
		);
	}, [rows]);

	const { createInvoiceMonthly } = useAccountingStore((state) => ({
		createInvoiceMonthly: state.createInvoiceMonthly,
	}));

	const { exportSammelrechnung } = useExportActions();
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);
	const [selectedJobDocumentIds, setSelectedJobDocumentIds] = useState<
		readonly number[]
	>([]);

	useEffect(() => {
		// if the date range changes, set all jobDocuments to selected that are within the range
		const newSelected = rows
			.filter((jobDocument) => isInDateRange(jobDocument, dateRange))
			.map((jobDocument) => jobDocument.id as number);
		setSelectedJobDocumentIds(newSelected);
	}, [dateRange]);

	const handleSelectAllClick = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.id as number);
			setSelectedJobDocumentIds(newSelected);
		} else {
			setSelectedJobDocumentIds([]);
			onManualSelect();
		}
	};

	const handleSelectJobDocument = (id: number) => {
		const selectedIndex = selectedJobDocumentIds.indexOf(id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedJobDocumentIds, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedJobDocumentIds.slice(1));
		} else if (selectedIndex === selectedJobDocumentIds.length - 1) {
			newSelected = newSelected.concat(
				selectedJobDocumentIds.slice(0, -1)
			);
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedJobDocumentIds.slice(0, selectedIndex),
				selectedJobDocumentIds.slice(selectedIndex + 1)
			);
		}
		setSelectedJobDocumentIds(newSelected);
		onManualSelect();
	};

	const handleCreateMonthlyInvoice = async () => {
		if (!organization) {
			showNotification({
				message: "Keine Organisation gefunden",
				type: "error",
			});
			return;
		}

		setLoading(true);

		const { bankAccount, error: bankAccountError } = getBankAccount({
			bankAccountsLookup: bankAccountsLookup,
			lab: organization,
			job: null,
			recipient: recipient,
		});
		if (!bankAccount) {
			Logger.log("Der Adressat hat kein Bankkonto");
			showNotification({
				message: "Der Adressat hat kein Bankkonto",
				type: "error",
			});
			setLoading(false);
			return null;
		}

		const { data: extendedJobDocuments } = await supabase
			.from("job_documents_extended_view")
			.select("*")
			.in("job_document_id", selectedJobDocumentIds);

		if (!extendedJobDocuments) {
			showNotification({
				message: "Keine erweiterten Auftragsdokumente gefunden",
				type: "error",
			});
			// We thorw an error to Sentry because if creating a monthly invoice should imply that there are job documents
			Logger.error("No extended job documents found");
			setLoading(false);
			return;
		}

		const sammelrechnungCalculationResult =
			calculateSammelrechnungRowsAndTotals(
				extendedJobDocuments as ExtendedJobDocument[], // TODO: fix type
				organization,
				recipient,
				clientsLookup
			);

		if (sammelrechnungCalculationResult.total.value < 0) {
			showNotification({
				message: "Die Summe der ausgewählten Rechnungen ist negativ",
				type: "error",
			});
			setLoading(false);
			return;
		}

		const clientId = (recipientType === "client" ? recipient.id : null) as
			| string
			| null;
		const guarantorId = (
			recipientType === "guarantor" ? recipient.id : null
		) as string | null;

		const {
			success: createInvoiceSuccess,
			data: createInvoiceData,
			error: createInvoiceError,
		} = await createInvoiceMonthly({
			invoiceValue: sammelrechnungCalculationResult.total.value,
			bankAccountId: bankAccount.id,
			clientId,
			guarantorId,
			organizationId: organization.id,
			jobDocumentIds: selectedJobDocumentIds as number[],
		});
		if (!createInvoiceSuccess || !createInvoiceData) {
			Logger.error(
				"Error creating invoice",
				{
					createInvoiceError,
					createInvoiceData,
				},
				sammelrechnungCalculationResult,
				extendedJobDocuments
			);
			showNotification({
				message: "Fehler beim Erstellen der Rechnung",
				type: "error",
			});
			setLoading(false);
			return;
		}

		const { invoiceNumber, filePath } = createInvoiceData;

		const { error: exportError } = await exportSammelrechnung({
			recipient: recipient,
			invoiceNumber,
			filePath,
			extendedJobDocuments: extendedJobDocuments as ExtendedJobDocument[], // TODO: fix type
		});

		if (exportError) {
			showNotification({
				message: "Fehler beim Exportieren der Rechnung",
				type: "error",
			});
			setLoading(false);
			return;
		}

		showNotification({
			message: "Sammelrechnung erstellt",
			type: "success",
			action: {
				label: "Anzeigen",
				onClick: () =>
					navigate(`/accounting?recipient=${recipient.id}`),
			},
		});
		setSelectedJobDocumentIds([]);
		onFetchData();
		setLoading(false);
	};

	const isSelected = (id: number) =>
		selectedJobDocumentIds.indexOf(id) !== -1;
	const numSelected = selectedJobDocumentIds.length;
	const rowCount = rows.length;
	return (
		<div style={{ width: "100%", border: "var(--border-sm)" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "10px 15px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Typography variant="h6">
						{(recipient?.first_name ?? " ") +
							" " +
							(recipient?.last_name ?? " ")}
					</Typography>
					<Chip
						label={rowCount + " Offen"}
						size="small"
						sx={{
							color: "var(--color-white)",
							textTransform: "uppercase",
							fontSize: "12px",
							borderRadius: "5px",
							padding: "2px 4px",
							marginLeft: "10px",
						}}
					/>
					{recipientType === RecipientTypeEnum.GUARANTOR && (
						<Chip
							label="Garant"
							size="small"
							sx={{
								textTransform: "uppercase",
								fontSize: "12px",
								borderRadius: "5px",
								padding: "2px 4px",
								margin: "0 10px",
							}}
						/>
					)}
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "20px",
					}}
				>
					<Button
						variant="contained"
						onClick={handleCreateMonthlyInvoice}
						disabled={selectedJobDocumentIds.length === 0}
						startIcon={
							loading && (
								<CircularProgress
									sx={{
										color: "var(--color-white)",
									}}
									color="inherit"
									size="15px"
								/>
							)
						}
					>
						Sammelrechnung erstellen
					</Button>
				</div>
			</div>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell padding="checkbox">
							<Checkbox
								color="primary"
								indeterminate={
									numSelected > 0 && numSelected < rowCount
								}
								checked={
									rowCount > 0 && numSelected === rowCount
								}
								onChange={handleSelectAllClick}
							/>
						</TableCell>
						<TableCell>Datum</TableCell>
						<TableCell>Patient</TableCell>
						<TableCell>Auftrag</TableCell>
						{recipientType === RecipientTypeEnum.GUARANTOR && (
							<TableCell align="left">Auftraggeber</TableCell>
						)}
						<TableCell align="left">Typ</TableCell>
						<TableCell align="left">Betrag</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows?.map((jobDocument, index) => {
						const isItemSelected = isSelected(
							jobDocument.id as number
						);
						const labelId = `enhanced-table-checkbox-${index}`;
						return (
							<TableRow
								hover
								onClick={() =>
									handleSelectJobDocument(
										jobDocument.id as number
									)
								}
								role="checkbox"
								aria-checked={isItemSelected}
								tabIndex={-1}
								key={jobDocument.id}
								selected={isItemSelected}
								sx={{ cursor: "pointer" }}
							>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={isItemSelected}
										inputProps={{
											"aria-labelledby": labelId,
										}}
									/>
								</TableCell>
								<TableCell component="th" scope="row">
									{jobDocument.date &&
										new Date(
											jobDocument.date
										).toLocaleDateString("de-CH")}
								</TableCell>
								<TableCell component="th" scope="row">
									{jobDocument.job_patient_id &&
										patientName(
											patientsLookup[
												jobDocument.job_patient_id
											]
										)}
								</TableCell>
								<TableCell component="th" scope="row">
									<Button
										sx={{
											textTransform: "none",
											justifyContent: "flex-start",
											paddingLeft: "10px",
											color: "var(--neutral-700)",
											fontWeight: "normal",
										}}
									>
										{jobDocument.job_code} -{" "}
										{jobDocument.job_title}
									</Button>
								</TableCell>
								{recipientType ===
									RecipientTypeEnum.GUARANTOR && (
									<TableCell align="left">
										{
											clientsLookup[
												jobDocument.client_id as string
											]?.title
										}{" "}
										{
											clientsLookup[
												jobDocument.client_id as string
											]?.first_name
										}{" "}
										{
											clientsLookup[
												jobDocument.client_id as string
											]?.last_name
										}
									</TableCell>
								)}
								<TableCell align="left">
									{jobDocument.type ===
										JobDocumentTypeEnum.DeliveryNote &&
										"Lieferschein"}
									{jobDocument.type ===
										JobDocumentTypeEnum.CreditNote &&
										"Gutschrift"}
								</TableCell>
								<TableCell align="left">
									{jobDocument.amount?.toFixed(2)}
								</TableCell>
							</TableRow>
						);
					})}
					<TableRow>
						{[...Array(4)].map((_, index) => (
							<TableCell key={index}></TableCell>
						))}
						<TableCell>
							<div className="font-medium text-end">Gesamt</div>
						</TableCell>
						<TableCell>
							{roundToRappen(
								rows.reduce(
									(acc, row) => acc + (row.amount ?? 0),
									0
								)
							).toFixed(2)}
						</TableCell>
					</TableRow>
					<TableRow>
						{[...Array(4)].map((_, index) => (
							<TableCell key={index}></TableCell>
						))}
						<TableCell>
							<div className="font-medium text-end">
								Ausgewählt
							</div>
						</TableCell>
						<TableCell>
							<div className="font-medium">
								{roundToRappen(
									rows
										.filter((row) =>
											isSelected(row.id as number)
										)
										.reduce(
											(acc, row) =>
												acc + (row.amount ?? 0),
											0
										)
								).toFixed(2)}
							</div>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</div>
	);
};
