import { getFileType } from "@/dentlab/src/components/file-icon/file-icon-utils";
import { FileIconComponent } from "@/dentlab/src/components/file-icon/file-icon.component";
import { ShareFileButton } from "@/dentlab/src/components/sharing/share-button/share-file-button.component";
import { useStorage } from "@/dentlab/src/hooks/useStorage";
import { useUploadPatientFiles } from "@/dentlab/src/hooks/useUploadPatientFiles";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { usePatientStore } from "@/dentlab/src/store/Patient";
import {
	FileWithShare,
	isShared,
	PatientWithShare,
} from "@/lib/supabase/supabaseTypes";
import { CloudDone, OpenInNew, Share } from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";
import clsx from "clsx";
import { DownloadIcon, Link, Plus } from "lucide-react";
import { useEffect, useState } from "react";

export const PatientDialogFilesSection: React.FC<{ patientId: string }> = ({
	patientId,
}) => {
	const {
		files: patientFiles,
		uploadFile,
		fetchFiles,
		updateFile,
	} = useUploadPatientFiles();
	const { getPatient } = usePatientStore((state) => ({
		getPatient: state.getPatient,
	}));
	const [patient, setPatient] = useState<PatientWithShare | undefined>(
		undefined
	);

	const fetchPatient = async () => {
		const patient = await getPatient(patientId);
		if (patient) {
			setPatient(patient);
		}
	};

	useEffect(() => {
		fetchFiles(patientId);
		fetchPatient();
	}, [patientId]);

	const [loading, setLoading] = useState<boolean>(false);

	const { openFileInNewTab, downloadFile } = useStorage();
	const [selectedFile, setSelectedFile] = useState<FileWithShare | null>(
		null
	);

	const { connectRelationshipId, organization } = useCentralStore(
		(state) => ({
			connectRelationshipId: state.connectRelationshipId,
			organization: state.organization,
		})
	);
	return (
		<div className="flex flex-col w-full gap-4">
			<div className="w-full flex justify-end px-4 h-10">
				<label
					htmlFor="patient-file"
					className={clsx(
						"cursor-pointer rounded h-8 w-24 flex gap-2 items-center justify-center hover:bg-neutral-100",
						{
							"cursor-wait": loading,
							"text-gray-300": loading,
						}
					)}
				>
					<Plus size={20} color={loading ? "grey" : "black"} /> Datei
				</label>
				<input
					disabled={loading}
					type="file"
					className="hidden"
					id="patient-file"
					onChange={async (e) => {
						if (
							!e.target.files ||
							e.target.files?.length <= 0 ||
							!patientId
						)
							return;
						setLoading(true);
						const file = e.target.files?.[0];
						await uploadFile(file, patientId);
						setLoading(false);
					}}
				/>
			</div>
			<div className="w-full px-8">
				{patientFiles.map((pf) => {
					return (
						<div
							key={pf.id}
							className="flex justify-between border-b h-16 items-center w-full"
						>
							<div className="flex gap-2">
								<FileIconComponent
									fileType={getFileType(
										pf.meta_data,
										pf.file_name
									)}
								/>
								<Badge
									color="success"
									badgeContent={
										pf.organization_id !==
										organization?.id ? (
											<Link size={12} />
										) : null
									}
								>
									{pf.file_name}
								</Badge>
							</div>
							<div className="flex">
								<IconButton
									onClick={() =>
										openFileInNewTab(pf.path_name as string)
									}
								>
									<OpenInNew />
								</IconButton>
								<IconButton
									onClick={() =>
										downloadFile(pf.path_name as string)
									}
								>
									<DownloadIcon />
								</IconButton>
								{connectRelationshipId && (
									<IconButton
										onClick={() => setSelectedFile(pf)}
									>
										{isShared(pf) ? (
											<CloudDone
												sx={{
													color: "green",
												}}
											/>
										) : (
											<Share />
										)}
									</IconButton>
								)}
							</div>
						</div>
					);
				})}
				{selectedFile && (
					<ShareFileButton
						setFile={(file: FileWithShare) => {
							updateFile(file);
							setSelectedFile(file);
						}}
						open={selectedFile !== null}
						setOpen={() => {
							setSelectedFile(null);
						}}
						file={selectedFile}
						associatedEntity={{
							patient,
							job: null,
						}}
					/>
				)}
			</div>
		</div>
	);
};
