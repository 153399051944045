import React, { useEffect, useState } from "react";
import { RightSidebar } from "../../../../dentlab/src/pages/job-page/job-page-components/right-sidebar.component";
import { ConnectTextInputForReply } from "./thread-sidebar-components/connect-text-input.component";
import { Reply } from "./thread-sidebar-components/reply.component";
import { Divider } from "@mui/material";
import { ActivityInThread } from "./thread-sidebar-components/activity-in-thread.component";
import { useCentralStore } from "../../../../dentlab/src/store/Central";
import { useRealtimeStore } from "@/lib/store/Realtime";
import { ActivityEntityType } from "@/lib/supabase/supabaseTypes";
import { Loading } from "../../animations/loading";

export const ThreadSidebar: React.FC<{
	activity: ActivityEntityType;
	onClose: () => void;
	top?: boolean;
}> = ({ activity, onClose, top }) => {
	return (
		<div
			style={{
				position: "fixed",
				height: top ? "100vh" : "95vh",
				right: 0,
				...(top && { top: 0 }),
				backgroundColor: "white",
			}}
		>
			<RightSidebar
				maximizeWidth
				title="Gespräch"
				onClose={() => {
					onClose();
				}}
			>
				<ReplyThread activityId={activity?.id} />
			</RightSidebar>
		</div>
	);
};

const RepliesList: React.FC<{ replies: any[] }> = ({ replies }) => {
	const p = useCentralStore((state) => state.profile);
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				paddingLeft: "10px",
				paddingTop: "10px",
				paddingRight: "10px",
				gap: "10px",
			}}
		>
			{replies.map((reply) => (
				<Reply
					key={reply.id}
					reply={reply}
					currentUser={p?.id == reply.profile_id}
				/>
			))}
		</div>
	);
};

export const ReplyThread = ({ activityId }: { activityId: number }) => {
	const [activity, setActivity] = useState<ActivityEntityType | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const {
		setActivityId,
		replies,
		emptyReplies,
		activitiesLookup,
		fetchActivity,
	} = useRealtimeStore((state) => ({
		replies: state.replies,
		setActivityId: state.setActivityId,
		emptyReplies: state.emptyReplies,
		fetchActivity: state.fetchActivity,
		activitiesLookup: state.activitiesLookup,
	}));

	useEffect(() => {
		if (!activityId) return;
		setActivityId(activityId);
		setLoading(true);
		const activityFromLookup = activitiesLookup[activityId];
		if (activityFromLookup) {
			setActivity(activityFromLookup);
			setLoading(false);
		} else {
			const fetchAndSetActivity = async () => {
				const fetchedActivity = await fetchActivity(activityId);
				setActivity(fetchedActivity);
				setActivityId(fetchedActivity?.id as number);

				setLoading(false);
			};

			fetchAndSetActivity();
		}
		return () => emptyReplies();
	}, [activitiesLookup, activityId]);

	if (!activityId) {
		return;
	}
	if (!loading && !activity) return null;

	return (
		<>
			{loading && !activity ? (
				<Loading />
			) : (
				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<div>
						<div
							style={{
								padding: "10px",
							}}
						>
							<ActivityInThread
								activity={activity as ActivityEntityType}
							/>
						</div>
						<Divider>{replies.length} Antworten</Divider>
						<RepliesList replies={replies} />
					</div>
					<ConnectTextInputForReply
						messageId={activityId}
						muiTextFieldProps={{
							fullWidth: true,
						}}
					/>
				</div>
			)}
		</>
	);
};
