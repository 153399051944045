import { createContext, useContext, useEffect, useState } from "react";
import { Logger } from "../../../../lib/logger/Logger";
import {
	PractsWithCtViewType,
	SupabaseTableEnum,
} from "../../../../lib/supabase/supabaseTypes";
import { useDatabaseFunctionsWithPromise } from "../../hooks/useDatabase";
import { useCentralStore } from "../../store/Central";
import { supabase } from "@/lib/supabase/supabaseClient";

type PractitionersContextType = {
	practitioners: PractsWithCtViewType[];
	practitionersLookupById: {
		[key: string]: PractsWithCtViewType;
	};
	practitionersLookupByAuthId: {
		[key: string]: PractsWithCtViewType;
	};
	practitionersByClientIdLookup: {
		[key: string]: PractsWithCtViewType[];
	};
};

/**
 * See comments under usePractitionersContext
 */
const PractitionersContext = createContext<{
	practitioners: PractitionersContextType;
	fetchPractitioners?: (organizationId: string) => void;
	updatePractitioners?: (practitioners: PractsWithCtViewType[]) => void;
}>({
	practitioners: {
		practitioners: [],
		practitionersLookupById: {},
		practitionersLookupByAuthId: {},
		practitionersByClientIdLookup: {},
	},
	fetchPractitioners: () => {},
	updatePractitioners: () => {},
});

export const PractitionersContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }: any) => {
	const [practitioners, setPractitioners] =
		useState<PractitionersContextType>({
			practitioners: [],
			practitionersLookupById: {},
			practitionersLookupByAuthId: {},
			practitionersByClientIdLookup: {},
		});
	const organizationId = useCentralStore((state) => state.organization?.id);

	useEffect(() => {
		if (organizationId) {
			fetchPractitioners(organizationId);
		}
	}, [organizationId]);

	const fetchPractitioners = async (organizationId_: string) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.PRACTS_WITH_CT)
			.select("*")
			.eq("organization_id", organizationId_);
		if (error) {
			Logger.error("Error fetching practitioners", error);
			return;
		}
		updatePractitioners(data);
	};

	const updatePractitioners = (practitioners: PractsWithCtViewType[]) => {
		// lookup for the primary key of the practitioners table
		const practitionersLookupById = practitioners.reduce(
			(acc: any, practitioner: PractsWithCtViewType) => ({
				...acc,
				[practitioner.id?.toString() ?? ""]: practitioner,
			}),
			{}
		);
		// auth id is a foreign key to the profiles table
		const practitionersLookupByAuthId = practitioners.reduce(
			(acc: any, practitioner: PractsWithCtViewType) =>
				practitioner.profile_id && {
					...acc,
					[practitioner.profile_id]: practitioner,
				},
			{}
		);
		const practitionersByClientIdLookup = practitioners.reduce(
			(acc: any, practitioner: PractsWithCtViewType) => {
				if (!practitioner.client_id) {
					return acc;
				}
				return {
					...acc,
					[practitioner.client_id]: acc[practitioner.client_id]
						? [...acc[practitioner.client_id], practitioner]
						: [practitioner],
				};
			},
			{}
		);

		setPractitioners({
			practitioners: practitioners,
			practitionersLookupById: practitionersLookupById,
			practitionersLookupByAuthId: practitionersLookupByAuthId,
			practitionersByClientIdLookup: practitionersByClientIdLookup,
		});
	};

	return (
		<PractitionersContext.Provider
			value={{
				practitioners,
				fetchPractitioners,
				updatePractitioners,
			}}
		>
			{children}
		</PractitionersContext.Provider>
	);
};

/**
 * usePractitionersContext
 * @returns practitioners - object with:
 * - practitioners - practitioners as array
 * - practitionersLookupById - look up practitioner by practitioners table primary key
 * - practitionersLookupByAuthId - look up practitioner by practitioners table foreign key to profiles table
 * - practitionersByClientIdLookup - practitioners as lookup object grouped by clientId
 * @returns updatePractitioners - use as simplfied way of setting practitioners (instead of setPractitioners which is not made available)
 * @returns fetchPractitioners - lazy way of resetting practitioners (don't use if component can reset practitioners directly using updatePractitioners)
 * @example const { practitioners: { practitioners, practitionersLookup, practitionersByClientIdLookup }, setPractitioners } = usePractitionersContext();
 */
export const usePractitionersContext = () => {
	const context = useContext(PractitionersContext);
	if (!context) {
		throw new Error(
			"usePractitionersContext must be used within a PractitionersContextProvider"
		);
	}
	return context;
};
