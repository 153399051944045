export type FileType =
	| "pdf"
	| "xml"
	| "img"
	| "txt"
	| "kv"
	| "ls"
	| "gs"
	| null;

export const getFileType = (
	fileMetaData: any,
	fileName: string | null
): FileType => {
	if (fileName?.toLowerCase().includes("lieferschein")) return "ls";
	else if (fileName?.toLowerCase().includes("kostenvoranschlag")) return "kv";
	else if (fileName?.toLowerCase().includes("gutschrift")) return "gs";

	const potentialFileExt = fileName?.split(".").pop();
	if (potentialFileExt) {
		if (["pdf", "xml", "img"].includes(potentialFileExt)) {
			return potentialFileExt as FileType;
		} else if (["jpg", "jpeg", "png"].includes(potentialFileExt)) {
			return "img";
		} else if (["txt", "doc", "docx"].includes(potentialFileExt)) {
			return "txt";
		}
	}

	if (
		fileMetaData &&
		typeof fileMetaData === "object" &&
		!Array.isArray(fileMetaData) &&
		"type" in fileMetaData
	) {
		return fileMetaData.type;
	}

	return null;
};
