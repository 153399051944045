import { z } from "zod";
import {
	OrganizationEntityType,
	PatientEntityType,
} from "@/lib/supabase/supabaseTypes";
import { CompleteJobDocumentInformation } from "../actions/useExportActions";
import { Logger } from "@/lib/logger/Logger";
import { XmlEndpointsEnum } from "./useExportXml";
import axios from "axios";
import { environment } from "../../lib/utils/environment";
import {
	validateCantonAbbreviation,
	validatePhone,
} from "../useForm/form-field-validation-functions";
import { getCantonFromPostalCode } from "../../lib/utils/utils-functions";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";
import {
	ProductionCountriesEnum,
	productionCountriesLookup,
} from "../../lib/constants/productionCountries";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { useCentralStore } from "../../store/Central";
import { getItemCode } from "./export-xml-utils";

function formatDateForSumexApi(date: string): string {
	const ATRDate = new Date(date);
	return ATRDate.toISOString().replace(".000Z", "");
}

function formatTimestampzForSumexApi(timestampz: string): string {
	return timestampz?.split(".")[0];
}

export const DocumentFieldsForAPI = z.object({
	/**
	 * We do not use ATRTPW (which sets the taxpunktwert) on the API side. This is fine because
	 * every job item has its own TPW, which we can safely assume because the tp_value column is not nullable.
	 * Having tp_values be not nullable is ok, because there are always upstream entities to set the tp_value even if the
	 * job item itself does not have one set (client tp_value, organization tp_value)
	 */
	ip: z.object({
		LABCanton: z.string().refine((val) => validateCantonAbbreviation(val), {
			message: "Die Postleitzahl der Organisation ist ungültig",
		}),
		LABCompany: z
			.string()
			.min(1, { message: "Name der Organisation benötigt" }),
		LABName: z
			.string()
			.min(1, { message: "Name der Organisation benötigt" }),
		LABStreet: z.string().catch(""),
		LABCity: z
			.string()
			.min(1, { message: "Stadt der Organisation benötigt" }),
		LABPhone: z.string().catch(""),
		LABEmail: z.string().catch(""),
		LABVATNumber: z.string().catch(""),
		LABGLN: z.string().catch(""),
		LABPLZ: z
			.string()
			.min(1, { message: "Postleitzahl der Organisation benötigt" }),
		ATRCode: z.string().min(1, { message: "Auftragscode benötigt" }),
		ATRNote: z.string().catch(""),
		ATRVAT: z.number({
			required_error: "MwSt. benötigt",
			invalid_type_error: "MwSt. muss eine Zahl sein",
		}),
		ATRProduction: z.string().catch(""),
		ATRDate: z.string().min(1, { message: "Auftragsdatum benötigt" }),
		ATRDiscount1: z.number({
			required_error: "Rabatt 1 benötigt",
			invalid_type_error: "Rabatt 1 muss eine Zahl sein",
		}),
		ATRDiscount2: z.number({
			required_error: "Rabatt 2 benötigt",
			invalid_type_error: "Rabatt 2 muss eine Zahl sein",
		}),
		PATCode: z.string().min(1, { message: "Patientencode benötigt" }),
		PATSalutation: z.string().catch(""),
		PATFirstName: z
			.string({
				required_error: "Vorname des Patienten benötigt",
				invalid_type_error: "Vorname des Patienten fehlt",
			})
			.min(1, { message: "Vorname des Patienten benötigt" }),
		PATLastName: z
			.string({
				required_error: "Nachname des Patienten benötigt",
				invalid_type_error: "Nachname des Patienten fehlt",
			})
			.min(1, { message: "Nachname des Patienten benötigt" }),
		PATBirthDate: z.string().catch(""),
		PATProvider: z
			.string()
			.nullable()
			.transform((val) => val ?? ""),
		KUNCode: z.string().min(1, { message: "Auftraggebercode benötigt" }),
		KUNSalutation: z
			.string()
			.min(1, { message: "Anrede des Auftraggebers wird benötigt" }),
		KUNFirstName: z
			.string()
			.min(1, { message: "Vorname des Auftraggebers wird benötigt" }),
		KUNLastName: z
			.string()
			.min(1, { message: "Nachname des Auftraggebers wird benötigt" }),
		KUNStreet: z
			.string()
			.min(1, { message: "Straße des Kunden wird benötigt" }),
		KUNCity: z
			.string()
			.min(1, { message: "Stadt des Kunden wird benötigt" }),
		KUNPhone: z
			.string()
			.nullable()
			.refine((val) => val === null || validatePhone(val), {
				message: "Die Telefonnummer ist ungültig",
			})
			.catch(""),
		KUNEmail: z.string().catch(""),
		KUNPLZ: z
			.string()
			.min(1, { message: "Postleitzahl des Kunden benötigt" }),
	}),
	documentTypes: z.array(
		z.object({
			code: z.string({
				required_error:
					"Der Code der Position fehlt oder ist ungültig (Falls es sich um einen Artikel handelt, muss der Code 2100.0, 2100.0, 2130.0, 2150.0, 2200.0, 2300.0, 2400.0, 2900.0, 3100.0, 3110.0, 3170.0, 3500.0 oder 3700.0 lauten).",
				invalid_type_error: "Code muss ein String sein.",
			}),
			description: z.string({
				required_error:
					"Bei einer der Positionen fehlt die Beschreibung",
				invalid_type_error: "Beschreibung muss ein String sein",
			}),
			quantity: z.number({
				required_error: "Bei einer der Positionen fehlt die Menge",
				invalid_type_error: "Menge muss eine Zahl sein",
			}),
			Date: z.string({
				required_error: "Bei einer der Positionen fehlt das Datum",
				invalid_type_error: "Datum muss Text sein",
			}),
			price: z.number({
				required_error: "Bei einer der Positionen fehlt der Preis",
				invalid_type_error: "Preis muss eine Zahl sein",
			}),
			TPW: z.number({
				required_error:
					"Bei einer der Positionen fehlt der Taxpunktwert",
				invalid_type_error: "Taxpunktwert muss eine Zahl sein",
			}),
			Art: z.number({
				required_error: "Bei einer der Positionen fehlt der Typ",
				invalid_type_error: "Typ muss eine Zahl sein",
			}),
		})
	),
});

interface FormatDocumentPropsForApiProps {
	documentProps: CompleteJobDocumentInformation & {
		patient: PatientEntityType;
		organization: OrganizationEntityType;
	};
	documentType:
		| JobDocumentTypeEnum.Quotation
		| JobDocumentTypeEnum.DeliveryNote;
}

export const formatDocumentPropsForApi = (
	props: FormatDocumentPropsForApiProps
): {
	success: boolean;
	data: null | z.infer<typeof DocumentFieldsForAPI>;
	error: string | null;
} => {
	const {
		documentProps: {
			organization,
			patient,
			client,
			job,
			jobDocument,
			jobItems,
		},
		documentType,
	} = props;
	const { profile } = useCentralStore.getState();

	if (
		jobItems.find((item) => item.type == JobItemTypeEnum.ARTICLE_FIXED_RATE)
	) {
		return {
			success: false,
			data: null,
			error: "Artikel vom Typ Arbeitspauschale wurden übersprungen. Versicherer akzeptieren keine undefinierten Aufwändungen.",
		};
	}

	const data = {
		ip: {
			LABCanton: organization.postal_code
				? getCantonFromPostalCode(Number(organization.postal_code))
				: "",
			LABCompany: organization.name,
			LABName: organization.name,
			LABStreet: organization.street,
			LABCity: organization.city,
			LABPhone: organization.phone_and_fax,
			LABEmail: organization.email ?? profile?.email,

			// LABVATNumber is only used for invoice requests (deliery notes)
			LABVATNumber: organization.mwst_number,
			LABGLN: organization.gln,
			LABPLZ: organization.postal_code,

			/**
			 * ATRCode
			 *
			 * For generalInvoiceRequest450.SetInvoice
			 * bstrRequestInvoiceID	The request ID is the main software's identification of the invoice ("Rechnungsnummer").
			 *
			 * For generalCreditRequest450.SetCreditObject
			 * The request credit ID is the main software's identification of the general credit ("Kostengutsprachenummer").
			 */
			ATRCode: job.code,

			/**
			 * ATRNote
			 *
			 * Only relevant for generalInvoiceRequest450.AddDiagnosis:
			 * bstrText	The textual description of the diagnosis as given in the corresponding diagnosis catalog defined by DiagnosisType .
			 */
			ATRNote:
				documentType === JobDocumentTypeEnum.DeliveryNote
					? job.title
					: "",
			ATRVAT: job.tax_rate,
			ATRProduction:
				job.prod_country_choice === "other"
					? job.prod_country_other
					: productionCountriesLookup[
							job.prod_country_choice as ProductionCountriesEnum
						],

			/**
			 * ATRDate
			 *
			 * For generalInvoiceRequest450.SetInvoice
			 * The request date is the main software's date of the invoice request ("Rechnungsdatum")
			 *
			 * For generalCreditRequest450.SetCreditObject
			 * The request credit date is the main software's date of the general credit ("Kostengutsprachedatum")
			 */
			ATRDate: formatDateForSumexApi(jobDocument.date),
			ATRDiscount1: jobDocument.discount_work,
			ATRDiscount2: jobDocument.discount_material,

			/**
			 * PATCode - Currently, this is not used in the API of either manager!
			 */
			PATCode: patient.code ?? "XX-0000",
			PATSalutation: patient.title,
			PATFirstName: patient.first_name,
			PATLastName: patient.last_name,
			PATBirthDate: formatDateForSumexApi(
				patient.birth_date ?? new Date().toISOString()
			),
			/**
			 * PATProvider - Currently, this is not used in the API of either manager!
			 */
			PATProvider: "SUVA",

			/**
			 * KUNCode - Currently, this is not used in the API of either manager!
			 */
			KUNCode: client.code ?? "XX-0000",
			KUNSalutation: client.title,
			KUNFirstName: client.first_name,
			KUNLastName: client.last_name,
			KUNStreet: client.street,
			KUNCity: client.city,
			KUNPhone:
				client.phone_business ??
				client.phone_mobile ??
				client.phone_personal ??
				"",
			KUNEmail: client.email,
			KUNPLZ: client.postal_code,
		},
		documentTypes: jobItems.map((item) => ({
			/**
			 * When the job item is of type tariff, the standard tariff code can be used.
			 * For articles, the code is used only internally and for the insurance the code is derived from the cluster.
			 */
			code: getItemCode(item),
			description: item.description,
			quantity: item.quantity,
			Date: formatTimestampzForSumexApi(
				item.modified_at ?? new Date().toISOString()
			),
			price: item.price,
			TPW: item.tp_value ?? 1,
			Art: item.type, // 0 = tariff, 1-5 different article types
		})),
	};

	const validatedData = DocumentFieldsForAPI.safeParse(data);

	if (!validatedData.success) {
		Logger.error("Error validating data for XML", {
			error: validatedData.error,
		});
		return {
			success: false,
			data: null,
			error: validatedData.error.issues
				.map(
					(issue) =>
						`${issue.message} (Feld: ${
							issue.path.join(".").split(".")[1]
						})`
				)
				.join(", "),
		};
	}

	return {
		success: true,
		data: validatedData.data,
		error: null,
	};
};

export const postDataToApi = async ({
	endpoint,
	data,
}: {
	endpoint: XmlEndpointsEnum;
	data: z.infer<typeof DocumentFieldsForAPI>;
}) => {
	return await axios({
		method: "POST",
		url: `${environment.VITE_XML_MICROSERVICE_URL}/${endpoint}`,
		data,
	});
};
