import { TextField } from "@mui/material";
import { useForm } from "../../../../hooks/useForm/useForm";
import { FormProps } from "../../../form-types";
import { Database, supabase } from "../../../../../../lib/supabase";
import { LanguageSelect } from "../../../form-input-components/selects/language.select";
import { ClientNameAndAddressSection } from "../input-components/client-name-and-address.section";
import { InactiveCheckboxAndSelect } from "../input-components/inactive-checkbox-and-select.component";
import { FormSectionWithTitle } from "../../../../components/form-section-with-title/form-section-with-title.component";
import { FormSectionWithSaveButton } from "../../../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { SupabaseTableEnum } from "../../../../../../lib/supabase/supabaseTypes";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";
import { useCustomDialogContext } from "@/dentlab/src/components/dialog-components/custom-dialog/custom-dialog.component";
import { useState } from "react";
import { useToast } from "@/dentlab/src/context/ToastContext";
import { ImageInput } from "../input-components/image-input";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { v4 as uuidv4 } from "uuid";
import { StorageBucketsEnum } from "@/dentlab/src/types/enums";

// Form to update (not create!) general client information
export const ClientInformationForm: React.FC<FormProps> = ({
	onSave = () => {},
	submitRef = null,
}) => {
	const { organization, client } = useCentralStore((state) => ({
		organization: state.organization,
		client: state.client,
	}));
	const { setIsPendingChanges } = useCustomDialogContext();
	const [profilePicFile, setProfilePicFile] = useState<File | null>(null);
	const { upsertClient } = useCentralStore((state) => ({
		upsertClient: state.upsertClient,
	}));

	const { showToast } = useToast();

	const {
		handleInputChange,
		handleCheckboxChange,
		formData,
		formErrors,
		handleValueChange,
		handleUpdate,
		isPendingChanges,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["clients"]["Row"]>(
		{
			organization_id: organization?.id,
		},
		SupabaseTableEnum.CLIENTS,
		true,
		client?.id,
		"Auftraggeber",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (!client?.id) return;
		event.preventDefault();
		if (profilePicFile) {
			if (!formData.avatar_path) {
				showToast("Fehler beim Hochladen des Avatars", "error");
				return;
			}
			const { error } = await supabase.storage
				.from(StorageBucketsEnum.ORG_V1)
				.upload(
					`${organization?.id}/${formData.avatar_path}`,
					profilePicFile
				);

			if (error) {
				showToast("Fehler beim Hochladen des Avatars", "error");
				return;
			}
		}
		delete formData.search_vector;
		const { success, data } = await handleUpdate(event, client?.id);
		if (success && data) {
			const client = data[0];
			delete client.search_vector;
			onSave(client);
			await upsertClient({
				...client,
			});
		} else {
			showNotification({
				message: "Fehler beim Speichern des Auftraggebers",
				type: "error",
			});
		}
	};

	const handleFileChange = (file: File | null) => {
		setProfilePicFile(file);
		handleValueChange("avatar_path", `avatars/${uuidv4()}/avatar`);
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			disabled={!isPendingChanges}
			margin="none"
			onResetForm={resetFormData}
		>
			<div
				style={{
					marginTop: "40px",
				}}
			>
				<ImageInput
					avatarPath={formData.avatar_path ?? ""}
					onFileChange={handleFileChange}
				/>
			</div>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<CustomTextField
				label="Kürzel"
				name="code"
				value={formData.code || ""}
				onChange={handleInputChange}
				size="small"
				sx={{
					maxWidth: "250px",
					marginTop: "30px",
				}}
				required
				validationError={formErrors?.code}
				fullWidth
			/>

			<ClientNameAndAddressSection
				formData={formData}
				formErrors={formErrors}
				onChange={handleInputChange}
				onCheckboxChange={handleCheckboxChange}
				onValueChange={(args) => {
					handleValueChange(args.name, args.value);
				}}
			/>
			<FormSectionWithTitle title="Sonstiges">
				<LanguageSelect
					value={formData.language || "de"}
					onChange={(value) => handleValueChange("language", value)}
				/>
				<TextField
					label="Externe Referenz"
					name="external_reference"
					value={formData.external_reference || ""}
					onChange={handleInputChange}
					variant="outlined"
					size="small"
					sx={{ my: 1, mr: 1 }}
					fullWidth
				/>
				<TextField
					label="Kurznotiz"
					name="note"
					value={formData.note || ""}
					onChange={handleInputChange}
					variant="outlined"
					size="small"
					sx={{ my: 1 }}
					fullWidth
				/>
				<InactiveCheckboxAndSelect
					formData={formData}
					onValueChange={(args) =>
						handleValueChange(args.name, args.value)
					}
					onCheckboxChange={handleCheckboxChange}
				/>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};
