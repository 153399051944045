import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ClientPageTitleWithSettingsPopup } from "../../pages-via-dialog/client-settings/client-page-title-with-settings-popup.component";
import { ClientMenu } from "./client-page-components/client-menu/client-menu.component";
import { ClientJobList } from "./client-page-components/client-job-list/client-job-list.component";
import {
	Splitter,
	SplitterOnChangeEvent,
	SplitterPaneProps,
} from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import { ClientNotFoundComponent } from "./client-components/client-not-found.component";
import { Loading } from "@/components/src/animations/loading";
import { useCentralStore } from "../../store/Central";
import { useJobStore } from "../../store/Jobs";
import { Logger } from "@/lib/logger/Logger";

export const ClientPage: React.FC = () => {
	const [panes, setPanes] = useState<Array<SplitterPaneProps>>([
		{ size: "250px", min: "175px", max: "350px", collapsible: true },
		{},
		{},
	]);
	const { clientId } = useParams();

	const createJob = useJobStore((state) => state.createJob);
	const navigate = useNavigate();

	const handleCreateJob = async () => {
		const job = await createJob();
		Logger.log("handleCreateJob", { job });
		if (job) {
			navigate(`/client/${job.client_id}/job/${job.id}`);
		}
	};

	const { client, clientLoading, clientsLoading, setClientId } =
		useCentralStore((state) => ({
			client: state.client,
			clientLoading: state.clientLoading,
			clientsLoading: state.clientsLoading,
			setClientId: state.setClientId,
		}));

	useEffect(() => {
		if (!clientId) return;
		setClientId(clientId);
	}, [clientId]);

	const onChange = (event: SplitterOnChangeEvent) => {
		setPanes(event.newState);
	};

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "j" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				handleCreateJob();
			}
		};
		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, []);

	if (clientsLoading || clientLoading) return <Loading />;
	if (!client) return <ClientNotFoundComponent />;

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
				width: "100%",
			}}
		>
			<Splitter
				panes={panes}
				onChange={onChange}
				style={{
					border: "none",
				}}
			>
				<div
					style={{
						backgroundColor:
							"var(--color-elevation-coloredSurface)",
						overflow: "hidden",
						height: "100%",
					}}
				>
					<div
						style={{
							position: "sticky",
							top: 0,
						}}
					>
						<ClientPageTitleWithSettingsPopup />
						<ClientMenu />
					</div>
					<div
						style={{
							overflow: "auto",
							height: "100%",
						}}
					>
						<ClientJobList />
					</div>
				</div>
				<div
					style={{
						flex: 1,
						display: "flex",
						overflow: "auto",
						height: "100%",
					}}
				>
					<Outlet />
				</div>
			</Splitter>
		</div>
	);
};
