import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { formatDate } from "../../lib/utils/utils-functions";

export const PdfTitleAndDate: React.FC<{
	title: string;
	date: string;
	generalConfig: PdfTemplateConfiguration["general"];
}> = ({ title, date, generalConfig }) => {
	const fontSize = {
		sm: "12px",
		md: "14px",
		lg: "16px",
	};

	return (
		<>
			<div
				aria-label="Betreff"
				style={{
					fontSize: fontSize[generalConfig.size ?? "lg"],
					fontWeight: "bold",
				}}
			>
				{title}
			</div>
			<div
				aria-label="Datum"
				style={{
					fontSize: generalConfig.size
						? fontSize[generalConfig.size]
						: undefined,
				}}
			>
				{formatDate(date)}
			</div>
		</>
	);
};
