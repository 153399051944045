import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";

const validClusters = [
	"2100",
	"2130",
	"2150",
	"2200",
	"2300",
	"2400",
	"2900",
	"3100",
	"3110",
	"3170",
	"3500",
	"3700",
];

export function getItemCode(item: any) {
	if (!item.code_e) {
		return undefined;
	} else if (item.type === JobItemTypeEnum.TARIFF) {
		return item.code; // code is the code for official use
	} else if (
		item.cluster &&
		typeof item.cluster === "string" &&
		validClusters.includes(item.cluster)
	) {
		return item.cluster.concat(".0");
	} else {
		return "2900.0";
	}
}
