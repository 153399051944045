import { FileWithShare } from "@/lib/supabase/supabaseTypes";

export const getFileTooltip = (file: FileWithShare) => {
	const createdAt = file.created_at
		? `Erstellt am: ${new Date(file.created_at).toLocaleDateString("de-ch")} ${
				file.created_at
					? `um ${new Date(file.created_at).toLocaleTimeString("de-ch")}`
					: ""
			}`
		: "";

	if (createdAt) {
		return createdAt;
	} else {
		return file?.file_name ?? "Datei";
	}
};
