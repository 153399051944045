import { Typography } from "@/components/src/typography/typography.component";
import { usePatientStore } from "@/dentlab/src/store/Patient";
import { LazyPatient } from "@/dentlab/src/store/Patient/patients.store";
import { FixedSizeList as List } from "react-window";
import {
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandDialog,
} from "@lab/src/components/ui/command";

import { SetStateAction, useCallback, useRef, useState } from "react";
import { Chip, LinearProgress } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const useDebounce = <T extends (...args: any[]) => any>(
	callback: T,
	delay: number
) => {
	const timeoutRef = useRef<NodeJS.Timeout>();

	return useCallback(
		(...args: Parameters<T>) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			return new Promise<ReturnType<T>>((resolve) => {
				timeoutRef.current = setTimeout(async () => {
					const result = await callback(...args);
					resolve(result);
				}, delay);
			});
		},
		[callback, delay]
	);
};

export const PatientCommandDialog: React.FC<{
	open: boolean;
	setOpen: React.Dispatch<SetStateAction<boolean>>;
	onNewBlankPatient: () => void;
	onNewPatientWithPrefill: (nameForPrefill: string) => void;
	onPatientSelect: (patientId: string) => void;
}> = ({
	open,
	setOpen,
	onNewBlankPatient,
	onNewPatientWithPrefill,
	onPatientSelect,
}) => {
	const { patientName, fetchPatientSearchResults, patientsLookup } =
		usePatientStore((state) => ({
			fetchPatientSearchResults: state.fetchPatientSearchResults,
			patientName: state.patientName,
			patientsLookup: state.patientsLookup,
		}));
	const debouncedFetch = useDebounce(fetchPatientSearchResults, 400);

	// To avoid an empty state, we show the first 20 patients in the lookup
	const [filteredPatients, setFilteredPatients] = useState<LazyPatient[]>(
		Object.values(patientsLookup)
			.filter((p): p is LazyPatient => "patient_id" in p)
			.slice(0, 20)
	);
	const [loading, setLoading] = useState<boolean>();
	const [searchTerm, setSearchTerm] = useState("");

	const onChange = async (e: string) => {
		setLoading(true);
		if (!e) {
			setFilteredPatients([]);
			setLoading(false);
			setSearchTerm("");
		} else {
			setSearchTerm(e);
			const searched = await debouncedFetch(e);
			setFilteredPatients(searched ?? []);
			setLoading(false);
		}
	};

	const handleDialogOpenChange = (open: boolean) => {
		setOpen(open);
		if (!open) {
			setSearchTerm("");
			setFilteredPatients([]);
		}
	};

	return (
		<CommandDialog open={open} onOpenChange={handleDialogOpenChange}>
			<CommandInput
				noListSearch
				placeholder="Patient suchen…"
				onKeyDown={(e) => {
					if (
						e.key.toLowerCase() === "enter" &&
						filteredPatients.length <= 0 &&
						searchTerm
					) {
						onNewPatientWithPrefill(searchTerm);
						setSearchTerm("");
						setOpen(false);
					}
				}}
				onValueChange={onChange}
			/>{" "}
			{loading && <LinearProgress />}
			<CommandList className="h-96 max-h-96">
				<CommandGroup>
					{!searchTerm && (
						<CommandItem
							onSelect={() => {
								onNewBlankPatient();
								setSearchTerm("");
								setOpen(false);
							}}
							className="flex h-12 font-medium pl-5 gap-2 w-full items-center justify-start"
						>
							<PersonAddIcon />
							<Typography variant="title-sm">
								Neuer Patient
							</Typography>
						</CommandItem>
					)}
					{searchTerm && (
						<CommandItem
							onSelect={() => {
								onNewPatientWithPrefill(searchTerm);
								setSearchTerm("");
								setOpen(false);
							}}
							className="flex h-12 font-medium pl-5 gap-2 w-full items-center justify-start"
						>
							<Typography variant="title-sm">
								<Chip label={searchTerm} size="small" /> anlegen
							</Typography>
						</CommandItem>
					)}
				</CommandGroup>{" "}
				<CommandGroup className="w-[500px]">
					<List
						height={400}
						itemCount={filteredPatients.length}
						itemSize={50}
						width="100%"
					>
						{({ index, style }) => {
							const patient = filteredPatients[index];
							const patientId = patient?.patient_id;
							const name = patientName(patient);
							return (
								<CommandItem
									key={patientId}
									className="flex cursor-pointer w-full h-full flex-row gap-2 items-center"
									style={style}
									onSelect={() => {
										onPatientSelect(patientId);
									}}
								>
									<div className="text-lg">{name}</div>
									<div className="text-gray-500">
										{patient.code}
									</div>
								</CommandItem>
							);
						}}
					</List>
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	);
};
