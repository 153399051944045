/**
 * @fileoverview Components for displaying files of different types and on different views.
 */
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
	FileEntityTypeWithUrl,
	FileWithShareWithUrl,
	SharesWithFileInfoWithUrl,
} from "../../hooks/useStorage";
import { Logger } from "../../../../lib/logger/Logger";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.js",
	import.meta.url
).toString();

export const PdfPreview: React.FC<{
	file:
		| FileEntityTypeWithUrl
		| FileWithShareWithUrl
		| SharesWithFileInfoWithUrl;
}> = ({ file }) => {
	Logger.log("PdfPreview", "file", file);
	return (
		<Document
			file={file.url}
			onLoadSuccess={() => {}}
			onLoadError={(error) => Logger.log("load error", error, file)}
			loading={<CircularProgress size={20} />}
		>
			<Page
				pageNumber={1}
				renderAnnotationLayer={false}
				renderTextLayer={false}
				noData={<p>No data.</p>}
				width={180}
			/>
		</Document>
	);
};

export const PdfFullScreen: React.FC<{
	file: FileEntityTypeWithUrl | FileWithShareWithUrl;
}> = ({ file }) => {
	const [numPages, setNumPages] = useState<number>();
	const [pageNumber, setPageNumber] = useState<number>(1);

	function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
		setNumPages(numPages);
	}

	// Function that's called when a key is pressed
	const handleKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case "ArrowRight":
				// If it's not the last page, increment the page number
				if (pageNumber < (numPages || 0)) {
					setPageNumber((prev) => prev + 1);
				}
				break;
			case "ArrowLeft":
				// If it's not the first page, decrement the page number
				if (pageNumber > 1) {
					setPageNumber((prev) => prev - 1);
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);

		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [pageNumber, numPages]);
	return (
		<>
			<Document
				file={file.url}
				onLoadSuccess={onDocumentLoadSuccess}
				onLoadError={(error) => Logger.log("load error", error, file)}
				loading={<CircularProgress size={20} />}
			>
				<Page
					pageNumber={pageNumber}
					renderAnnotationLayer={false}
					renderTextLayer={false}
					noData={<p>No data.</p>}
					// TODO: at some point change this when window size changes
					height={window.innerHeight - 100}
				/>
			</Document>
			<IconButton
				onClick={() => {
					setPageNumber((prev) => Math.max(prev - 1, 1));
				}}
				style={{
					position: "absolute",
					top: "50%",
					left: "0.5rem",
				}}
			>
				<ArrowBackIosIcon
					style={{
						color: "white",
						fontSize: "2.5rem",
					}}
				/>
			</IconButton>
			<p style={{ textAlign: "center", color: "white", paddingTop: 20 }}>
				Page {pageNumber} of {numPages}
			</p>
			<IconButton
				onClick={() => {
					setPageNumber((prev) => Math.min(prev + 1, numPages || 0));
				}}
				style={{
					position: "absolute",
					top: "50%",
					right: "0.5rem",
				}}
			>
				<ArrowForwardIosIcon
					style={{
						color: "white",
						fontSize: "2.5rem",
					}}
				/>
			</IconButton>
		</>
	);
};
