type Status = {
	text: string;
	color: string;
};
export function getStatus(accepted: boolean, rejected: boolean): Status {
	switch (true) {
		case accepted:
			return { text: "wurde akzeptiert.", color: "green" };
		case rejected:
			return { text: "wurde abgelehnt.", color: "red" };
		default:
			return { text: "ist ausstehend.", color: "orange" };
	}
}
