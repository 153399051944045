import { TableConfig } from "../../../../../types/types";
import { CheckboxCustomCell } from "../../../../../components/custom-table/custom-cells/checkbox-custom-cell.component";
import { AmountCustomCell } from "../../../../../components/custom-table/custom-cells/amount-custom-cell.component";
import { TextCustomCell } from "../../../../../components/custom-table/custom-cells/text-custom-cell.component";
import { TypeCustomCell } from "../../../../../components/custom-table/custom-cells/type-custom-cell.component";
import { TechnicianSelectCustomCell } from "@/dentlab/src/components/custom-table/custom-cells/technician-select-custom-cell.component";
import { PercentageCustomCell } from "@/dentlab/src/components/custom-table/custom-cells/percentage-custom-cell.component";
import { GridColumnProps } from "@progress/kendo-react-grid";
import { PriceSelect } from "@/dentlab/src/components/custom-table/custom-cells/price-select-custom-cell.component";
import { TPValueCustomCell } from "@/dentlab/src/components/custom-table/custom-cells/tp-value-custom-cell.component";

const WIDTH_S = "50px";
const WIDTH_M = "75px";
const WIDTH_L = "100px";
const WIDTH_XL = "200px";

const createColumnConfig = (
	field: string,
	title: string,
	width: string,
	cellType: any,
	editor: "text" | "numeric" | "boolean" = "text"
): GridColumnProps & { show: boolean } => ({
	field,
	editor,
	title,
	width,
	editable: false, // All fields are set to editable false, because we use custom cells
	cells: { data: cellType },
	show: true,
});

export const jobItemsTableConfigDefault: TableConfig = {
	columns: [
		createColumnConfig(
			"selected",
			" ", // This is displayed when there are not entries
			WIDTH_S,
			CheckboxCustomCell,
			"boolean"
		),
		createColumnConfig("code", "Code", WIDTH_M, TextCustomCell),
		createColumnConfig(
			"quantity",
			"Menge",
			WIDTH_S,
			TextCustomCell,
			"numeric"
		),
		createColumnConfig(
			"profile_id",
			"Techniker 1",
			WIDTH_L,
			TechnicianSelectCustomCell
		),
		// This field does not exist in the database as it is calculated from the other two percentages
		// However, it is used to identify that its the percentage of the first technician in TechnicianPercentageCustomCell
		createColumnConfig(
			"techn_perc",
			"Techn. 1 (%)",
			WIDTH_M,
			PercentageCustomCell
		),
		createColumnConfig(
			"profile2_id",
			"Techniker 2",
			WIDTH_L,
			TechnicianSelectCustomCell
		),
		createColumnConfig(
			"techn_perc_2",
			"Techn. 2 (%)",
			WIDTH_M,
			PercentageCustomCell
		),
		createColumnConfig(
			"profile3_id",
			"Techniker 3",
			WIDTH_L,
			TechnicianSelectCustomCell
		),
		createColumnConfig(
			"techn_perc_3",
			"Techn. 3 (%)",
			WIDTH_M,
			PercentageCustomCell
		),
		createColumnConfig(
			"description",
			"Bezeichnung",
			WIDTH_XL,
			TextCustomCell
		),
		createColumnConfig("note", "Notiz", WIDTH_L, TextCustomCell),
		createColumnConfig("lot", "LOT", WIDTH_S, TextCustomCell),
		createColumnConfig(
			"is_req_dr_tax",
			"ZAZ-St.",
			WIDTH_S,
			CheckboxCustomCell,
			"boolean"
		),
		createColumnConfig(
			"tp_value",
			"TP-Wert",
			WIDTH_S,
			TPValueCustomCell,
			"numeric"
		),
		createColumnConfig(
			"discount",
			"Rabatt",
			WIDTH_M,
			PercentageCustomCell,
			"numeric"
		),
		createColumnConfig("price", "Preis/E", WIDTH_M, PriceSelect, "numeric"),
		createColumnConfig("amount", "Betrag", WIDTH_S, AmountCustomCell),
		createColumnConfig(
			"is_warranty",
			"Garantie",
			WIDTH_S,
			CheckboxCustomCell,
			"boolean"
		),
		createColumnConfig(
			"tax_rate",
			"MwSt.",
			WIDTH_M,
			PercentageCustomCell,
			"numeric"
		),
		createColumnConfig("type", "Art", WIDTH_M, TypeCustomCell),
		createColumnConfig(
			"additional_text",
			"Zusatztext",
			WIDTH_L,
			TextCustomCell
		),
		createColumnConfig(
			"is_print_additional_text",
			"Zusatz drucken?",
			WIDTH_L,
			CheckboxCustomCell,
			"boolean"
		),
	],
	dataState: {
		group: [
			{
				field: "type",
				aggregates: [{ field: "price", aggregate: "sum" }],
			},
		],
	},
};
