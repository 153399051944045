import { FormGroupProps, FormGroupPropsValueChange } from "../../form-types";
import { TitleSelect } from "../selects/title.select";
import { CustomTextField } from "../custom-input-components/custom-text-field.component";

type FieldType = "title" | "first_name" | "last_name";

// make onValueChange required
interface TitleAndNameGroupProps extends FormGroupProps {
	onValueChange: (valueChange: FormGroupPropsValueChange) => void;
	requiredFields?: FieldType[];
}

export const TitleAndNameGroup: React.FC<TitleAndNameGroupProps> = ({
	formData,
	formErrors,
	onChange,
	onValueChange,
	customNaming = null,
	size = "small",
	direction = "row",
	requiredFields = [],
}) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: direction,
				gap: direction === "row" ? "0.5rem" : "0",
			}}
		>
			<TitleSelect
				onChange={(value: any) =>
					onValueChange({
						name: customNaming ? customNaming.title : "title",
						value: value,
					})
				}
				value={
					customNaming
						? formData[customNaming.title]
						: (formData.title ?? "")
				}
				size={size}
				width={direction === "row" ? "34%" : "100%"}
				required={requiredFields.includes("title")}
			/>
			<CustomTextField
				id="first_name"
				label="Vorname"
				name={customNaming ? customNaming.first_name : "first_name"}
				value={
					customNaming
						? formData[customNaming.first_name]
						: (formData.first_name ?? "")
				}
				onChange={onChange}
				size={size}
				sx={{ width: direction === "row" ? "33%" : "100%" }}
				required={requiredFields.includes("first_name")}
				validationError={formErrors.first_name}
			/>
			<CustomTextField
				id="last_name"
				label="Nachname"
				name={customNaming ? customNaming.last_name : "last_name"}
				value={
					customNaming
						? formData[customNaming.last_name]
						: (formData.last_name ?? "")
				}
				onChange={onChange}
				size={size}
				sx={{ width: direction === "row" ? "33%" : "100%" }}
				required={requiredFields.includes("last_name")}
				validationError={formErrors.last_name}
			/>
		</div>
	);
};
