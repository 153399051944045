import { FileEntityTypeWithUrl, useStorage } from "../../hooks/useStorage";
import { SimpleMenu } from "../simple-menu.component";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PrintIcon from "@mui/icons-material/Print";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Logger } from "../../../../lib/logger/Logger";
import { useCentralStore } from "../../store/Central";
import { showNotification } from "../../store/Central/selectors";

/**
 * FileOptions - provides delete, download, sharing options for a file
 * @param file - file object to display options for
 */
export const FileOptions: React.FC<{
	file: FileEntityTypeWithUrl;
	setFile: (file: FileEntityTypeWithUrl) => void;
	clientId: string;
	onDeleteStart: () => void;
	onDeleteSuccess: () => void;
	variant?: "menu" | "button";
}> = ({ file, setFile, onDeleteStart, onDeleteSuccess, variant = "menu" }) => {
	// const { jobId: jobIdString } = useParams<{ jobId: string }>(); // for assigning notifications // Not being used
	// const jobId = jobIdString ? parseInt(jobIdString) : null; // Not being used
	const { openFileInNewTab } = useStorage();
	const { downloadFile } = useStorage();

	const handleDownload = () => {
		downloadFile(file.path_name as string);
	};

	const handlePrint = () => {
		if (!file.path_name) {
			Logger.error("FileOptions: file.path_name is null");
			return;
		}
		openFileInNewTab(file.path_name as string);
	};

	const menuItems = [
		{ label: "Herunterladen", value: "download" },
		{ label: "Drucken", value: "print" },
	];

	if (variant === "button") {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<IconButton onClick={handlePrint}>
					<PrintIcon
						style={{
							color: "white",
							fontSize: "2rem",
						}}
					/>
				</IconButton>
				<IconButton onClick={handleDownload}>
					<DownloadIcon
						style={{
							color: "white",
							fontSize: "2rem",
						}}
					/>
				</IconButton>
				<SimpleMenu
					buttonVariant="icon"
					buttonContent={
						<MoreVertIcon
							style={{
								color: "white",
								fontSize: "2rem",
							}}
						/>
					}
					menuItems={menuItems}
					onSelect={(value) => {
						if (value === "download") {
							handleDownload();
						} else if (value === "print") {
							handlePrint();
						} else {
							showNotification({
								message: "Unbekannter Wert " + value,
								type: "error",
							});
						}
					}}
				/>
			</div>
		);
	} else {
		return (
			<SimpleMenu
				buttonVariant="icon"
				buttonContent={<MoreVertIcon />}
				menuItems={menuItems}
				onSelect={(value) => {
					if (value === "download") {
						handleDownload();
					} else if (value === "print") {
						handlePrint();
					} else {
						showNotification({
							message: "Unbekannter Wert " + value,
							type: "error",
						});
					}
				}}
			/>
		);
	}
};
