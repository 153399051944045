import { create } from "zustand";
import { JobListSlice, createJobListStore } from "./job-list.store";
import {
	createJobDocumentStore,
	JobDocumentSlice,
} from "./job-documents.store";
import { createJobStore, JobSlice } from "./job.store";
import { createJobItemStore, JobItemSlice } from "./job-items.store";

export type JobStoreUnion = JobListSlice &
	JobDocumentSlice &
	JobSlice &
	JobItemSlice;

/**
 * The main store to handle everything that's going on in the job page.
 */
export const useJobStore = create<JobStoreUnion>((...args) => ({
	...createJobListStore(...args),
	...createJobDocumentStore(...args),
	...createJobStore(...args),
	...createJobItemStore(...args),
}));
