import React, { useEffect, useState } from "react";
import { EditableTitle } from "../../../../../../components/src/editable-title/editable-title.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";

export const JobTitleForm: React.FC = () => {
	const { job, updateJob } = useJobStore((state) => ({
		job: state.job,
		updateJob: state.updateJob,
	}));

	const [title, setTitle] = useState<string>("");

	useEffect(() => {
		setTitle(job?.title ?? "");
	}, [job]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const handleSubmit = async () => {
		if (title && job?.title !== title) updateJob("title", title);
	};

	const handleReset = () => {
		setTitle(job?.title ?? "");
	};

	return (
		<>
			<EditableTitle
				name="title"
				value={title ?? ""}
				onChange={handleInputChange}
				onSubmit={handleSubmit}
				onReset={handleReset}
			/>
		</>
	);
};
