import React from "react";
import { JobEntityType } from "../../../../lib/supabase/supabaseTypes";
import { getStatus } from "./utils";

export const ActivityJobRequestLink: React.FC<{
	job: JobEntityType;
	accepted: boolean;
	rejected: boolean;
}> = ({ job, accepted, rejected }) => {
	const status = getStatus(accepted, rejected);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "start",
				gap: "10px",
				fontSize: "14px",
			}}
		>
			{" "}
			<p
				style={{
					fontWeight: "500",
				}}
			>
				{job?.title}
			</p>
			<p
				style={{
					color: status.color,
					fontWeight: "400",
					opacity: 0.5,
				}}
			>
				({status.text})
			</p>
		</div>
	);
};
