import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import "../fonts.css";
import { PdfTemplateType } from "../document-template.types";
import parse from "html-react-parser";
import { getConfig } from "../../context/util";
import { OrganizationsImg } from "./organizations-img.component";
import { getRtfWidth } from "../utils";

export const PdfFooter = ({
	pdfTemplateConfiguration,
	templateType,
}: {
	pdfTemplateConfiguration: PdfTemplateConfiguration;
	templateType: PdfTemplateType;
}) => {
	const generalConfig = pdfTemplateConfiguration.general;
	const pdfConfig = pdfTemplateConfiguration?.[templateType] || {};

	const config = getConfig(pdfConfig, generalConfig);

	const fontSize = {
		sm: "6pt",
		md: "8pt",
		lg: "10pt",
	};

	return (
		<div>
			<div
				aria-label="Kommentar"
				style={{
					width: getRtfWidth(pdfConfig.commentText?.width),
					paddingTop: "5mm",
					fontSize: fontSize[generalConfig.size ?? "lg"],
					display: "flex",
					justifyContent: generalConfig.commentAlignment,
					textAlign: generalConfig.commentAlignment,
				}}
			>
				{parse(pdfConfig.commentText?.text ?? "")}
			</div>
			<div
				aria-label="Fusszeile"
				style={{
					width: getRtfWidth(pdfConfig.footerText.width),
					paddingTop: "5mm",
					fontSize: fontSize[generalConfig.size ?? "lg"],
					display: "flex",
					justifyContent: generalConfig.footerAlignment,
					textAlign: generalConfig.footerAlignment,
				}}
			>
				{parse(pdfConfig.footerText.text ?? "")}
			</div>
			{config.organizationsImgPosition === "bottom" && (
				<OrganizationsImg
					config={config}
					generalConfig={generalConfig}
				/>
			)}
		</div>
	);
};
