import { useEffect, useState } from "react";
import { _validateField } from "../hooks/useForm/form-field-validation-functions";
import { Logger } from "../../../lib/logger/Logger";
import { Paper } from "@mui/material";
import { SupabaseTableEnum } from "../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "../store/Central";
import { HeadingWithButton } from "../components/heading-with-button.component";
import { DataGrid, GRID_DEFAULT_LOCALE_TEXT } from "@mui/x-data-grid";
import { SmallDialog } from "../components/small-dialog.component";
import { CustomTextField } from "../forms/form-input-components/custom-input-components/custom-text-field.component";
import { showNotification } from "../store/Central/selectors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { supabase } from "@/lib/supabase";
import { RolesEnum } from "../types/enums";
import { useUserManagementStore } from "../store/UserManagement";
import { Translate } from "../components/translate/translate.component";

const columns = [
	{
		field: "email",
		headerName: "E-Mail",
		width: 200,
	},
	{
		field: "first_name",
		headerName: "Vorname",
		width: 150,
	},
	{
		field: "last_name",
		headerName: "Nachname",
		width: 150,
	},
	{
		field: "invited",
		headerName: "Eingeladen",
		width: 150,
	},
	{
		field: "role",
		headerName: "Rolle",
		width: 150,
	},
	{
		field: "accepted",
		headerName: "Einladung angenommen",
		width: 150,
	},
];

export const UserManagementPage: React.FC = () => {
	const { organizationId } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
		role: state.role ?? 2,
	}));
	const { members, getMembers } = useUserManagementStore((state) => ({
		members: state.members,
		getMembers: state.getMembers,
	}));
	useEffect(() => {
		getMembers();
	}, []);

	const [email, setEmail] = useState("");
	const [validationError, setValidationError] = useState<string | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = "email";
		const error = _validateField(name, value);
		setEmail(value);
		setValidationError(error);
	};

	const closeDialog = () => {
		setDialogOpen(false);
		setEmail("");
		setValidationError(null);
	};

	const openDialog = () => {
		setDialogOpen(true);
	};

	const submitInvite = async () => {
		if (validationError) {
			showNotification({
				message: "Bitte überprüfen Sie Ihre Eingabe.",
				type: "error",
			});
			return;
		}

		if (!organizationId) {
			showNotification({
				message:
					"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
				type: "error",
			});
			return;
		}

		const { error } = await supabase
			.from(SupabaseTableEnum.ALLOWED_SIGNUPS_LAB)
			.insert({
				email,
				role,
				organization_id: organizationId,
			});

		if (error) {
			showNotification({
				message:
					"Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
				type: "error",
			});
			Logger.error("submitInvite", error, organizationId, email, role);
			return;
		}

		showNotification({
			message: "Einladung erfolgreich versendet.",
			type: "success",
		});
		getMembers();
		closeDialog();
	};

	const [role, setRole] = useState(RolesEnum.MEMBER);

	const changeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		setRole(parseInt(value));
	};

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				flexDirection: "column",
				padding: "20px",
				paddingTop: "10px",
			}}
		>
			<HeadingWithButton
				heading="Mitarbeiter"
				buttonLabel="Mitarbeiter hinzufügen"
				onClick={openDialog}
			/>
			<Paper>
				<DataGrid
					localeText={{
						columnMenuSortAsc: "Sortieren nach aufsteigend",
						columnMenuSortDesc: "Sortieren nach absteigend",
						columnMenuFilter: "Filtern",
						columnMenuHideColumn: "Spalte ausblenden",
						columnMenuManageColumns: "Spalten verwalten",
						columnsPanelTextFieldLabel: "Spalte suchen",
						columnsPanelShowAllButton: "Alle anzeigen",
						columnsPanelHideAllButton: "Alle ausblenden",
						columnsPanelTextFieldPlaceholder: "Spaltentitel",
					}}
					rows={members}
					columns={columns}
					sx={{
						height: "100%",
						width: "100%",
						border: 0,
						"& .MuiDataGrid-footerContainer": {
							display: "none",
						},
					}}
				/>
			</Paper>
			<SmallDialog
				dialogTitle="Mitarbeiter einladen"
				dialogText="Geben Sie die E-Mail-Adresse des Mitarbeiters ein, den Sie einladen möchten."
				open={dialogOpen}
				onCancel={closeDialog}
				onSave={submitInvite}
				dialogActions={["cancel", "save"]}
				dialogActionsText={{ cancel: "Abbrechen", save: "Einladen" }}
			>
				<div className="flex flex-col gap-2">
					<CustomTextField
						label="E-Mail"
						value={email}
						onChange={handleChange}
						validationError={validationError}
					/>
					<FormControl>
						<FormLabel>Rolle</FormLabel>
						<RadioGroup value={role} onChange={changeRole}>
							<FormControlLabel
								value={RolesEnum.MEMBER}
								control={<Radio />}
								label="Mitarbeiter"
							/>
							<FormControlLabel
								value={RolesEnum.ADMIN}
								control={<Radio />}
								label="Administrator"
							/>
						</RadioGroup>
					</FormControl>
				</div>
			</SmallDialog>
			{/* Hack to give the paper bottom space*/}
			<div className="text-3xl  opacity-0">Lorem</div>
		</div>
	);
};
