import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { FileOptions } from "./file-options.component";
import { Logger } from "../../../../lib/logger/Logger";
import { PdfPreview } from "./file-display.components";
import { FileEntityTypeWithUrl } from "../../hooks/useStorage";
import { getFileType } from "../file-icon/file-icon-utils";
import { FileIconComponent } from "../file-icon/file-icon.component";

/**
 * FileCard - Component previewing a file, with options and possibility to open in dialog
 * @param fileType - type of file to display
 * @param fileName - name of file to display
 * @param children - preview of file to display
 * @returns
 */
export const FileCard: React.FC<{
	file: FileEntityTypeWithUrl;
	setFile: (file: FileEntityTypeWithUrl) => void;
	onDelete: () => void;
	noPreview?: boolean;
}> = ({ file, setFile, onDelete, noPreview = false }) => {
	const fileType = getFileType(file?.meta_data, file?.file_name);
	return (
		<div>
			<Card
				style={{
					height: "100%",
				}}
			>
				<CardHeader
					sx={{
						pb: !noPreview && fileType === "pdf" ? "0px" : "14px",
					}}
					avatar={<FileIconComponent fileType={fileType} />}
					title={
						<Typography
							style={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "80px",
								fontSize: "0.875rem",
							}}
						>
							{file.file_name}
						</Typography>
					}
					action={
						<FileOptions
							file={file}
							clientId={file.client_id?.toString() ?? ""} // TODO: not good
							onDeleteStart={() => {
								Logger.log("");
							}} // TODO: provide user feedback/progress indicator
							onDeleteSuccess={onDelete}
							variant="menu"
							setFile={setFile}
						/>
					}
					// onClick={() => setIsOptionsOpen(true)}
				/>
				{!noPreview && fileType === "pdf" && (
					<CardContent
						sx={{
							padding: "0px",
						}}
					>
						<div style={{ maxHeight: "100px" }}>
							<PdfPreview file={file} />
						</div>
					</CardContent>
				)}
			</Card>
		</div>
	);
};
