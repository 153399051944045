import { useCentralStore } from "@/dentlab/src/store/Central";
import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase/supabaseClient";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { useEffect, useState } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { CircularProgress } from "@mui/material";

export const CodeSuggestions: React.FC<{
	onAddTariff: (value: string) => void;
	onAddArticle: (value: string) => void;
	jobItemCodes: string[];
}> = ({ onAddTariff, onAddArticle, jobItemCodes }) => {
	const { organizationId } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
	}));
	const [suggestions, setSuggestions] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchSuggestions = async () => {
		if (!organizationId) return;

		setIsLoading(true);

		let query = supabase
			.from(SupabaseTableEnum.CODE_PAIRS_RLS)
			.select("code_2")
			.eq("organization_id", organizationId)
			.order("frequency", { ascending: false })
			.limit(3);

		if (jobItemCodes.length > 0) {
			query = query
				.in("code", jobItemCodes)
				.not("code_2", "in", `(${jobItemCodes.join(",")})`);
		}

		const { data, error } = await query;

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			setSuggestions([
				...new Set(data.map((item) => item.code_2 as string)),
			]);
		}

		setIsLoading(false);
	};

	const handleSuggestionClick = (suggestion: string) => {
		// Hack so that we don't have to understand whether the code is a tariff or article
		onAddTariff(suggestion);
		onAddArticle(suggestion);
	};

	useEffect(() => {
		fetchSuggestions();
	}, [JSON.stringify(jobItemCodes)]);

	return (
		<div className="flex flex-row gap-0.5 items-center justify-center p-1">
			{isLoading ? (
				<div className="w-full h-full flex items-center justify-center">
					<CircularProgress size={16} />
				</div>
			) : (
				suggestions.map((suggestion) => (
					<div
						key={suggestion}
						onClick={() => handleSuggestionClick(suggestion)}
						className="text-sm cursor-pointer hover:bg-gray-100 
						border border-gray-200 flex items-center justify-center 
						gap-1 py-0.5 px-3 rounded-md"
					>
						<AutoAwesomeIcon sx={{ fontSize: "14px" }} />
						{suggestion}
					</div>
				))
			)}
		</div>
	);
};
