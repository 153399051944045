import {
	Box,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from "@mui/material";
import { UserAvatarComponent } from "../../../../../components/user-avatar-component";
import { useState } from "react";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DescriptionIcon from "@mui/icons-material/Description";
import { useNavigate } from "react-router-dom";
import { OrganizationSettingsDialog } from "../../../../../pages-via-dialog/organization-settings/organization-settings.dialog";
import { PrivacySwitch } from "./header-bar-settings-group-components/privacy-switch/privacy-switch.component";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { RolesEnum } from "@/dentlab/src/types/enums";

// TODO: can be refactored with CustomIconWithAnchoredMenu component

const settings = [
	{
		name: "Kontoeinstellungen",
		link: "/account-settings",
	},
];

export const HeaderBarSettingsGroup: React.FC = () => {
	const navigate = useNavigate();
	const role = useCentralStore((state) => state.role ?? 2);

	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const [isOrganizationSettingsOpen, setIsOrganizationSettingsOpen] =
		useState(false);

	const adminSettings = [
		{
			name: "Mein Labor",
			onClick: () => {
				setIsOrganizationSettingsOpen(true);
			},
			icon: <CorporateFareIcon fontSize="medium" />,
		},
		{
			name: "PDF-Gestaltung",
			onClick: () => navigate("/document-settings"),
			icon: <DescriptionIcon fontSize="medium" />,
		},
		{
			name: "Desktop-Dienst",
			onClick: () => {
				navigate("/desktop-systems");
			},
			icon: <ConnectedTvIcon fontSize="medium" />,
		},
	];

	if (role <= RolesEnum.OWNER) {
		adminSettings.push({
			name: "Benutzerverwaltung",
			onClick: () => navigate("/user-management"),
			icon: <PeopleIcon fontSize="medium" />,
		});
		adminSettings.push({
			name: "Abrechnung",
			onClick: () => navigate("/abonnement"),
			icon: <ReceiptIcon fontSize="medium" />,
		});
	}

	return (
		<>
			<OrganizationSettingsDialog
				open={isOrganizationSettingsOpen}
				onClose={() => setIsOrganizationSettingsOpen(false)}
			/>
			<div className="header-bar-settings-group">
				<PrivacySwitch />

				<Tooltip title="Einstellungen öffnen">
					<IconButton
						onClick={handleOpenUserMenu}
						data-testid="profile-settings"
						sx={{ pr: 2 }}
					>
						<UserAvatarComponent />
					</IconButton>
				</Tooltip>
				<Menu
					sx={{ mt: "45px" }}
					id="menu-appbar"
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					keepMounted
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					<div style={{ paddingTop: "2px" }}>
						{role <= RolesEnum.ADMIN && (
							<div>
								<Typography
									sx={{ pl: "15px" }}
									variant="overline"
									display="block"
									gutterBottom
								>
									Admin Einstellungen
								</Typography>
								{adminSettings.map((entry: any, i: number) => (
									<MenuItem
										key={i}
										onClick={() => {
											handleCloseUserMenu();
											entry.onClick();
										}}
										sx={{ pl: "20px" }}
										data-testid="setting-option"
									>
										<ListItemIcon>
											{entry.icon}
										</ListItemIcon>
										<Typography textAlign="center">
											{entry.name}
										</Typography>
									</MenuItem>
								))}
							</div>
						)}
						<div>
							<Typography
								sx={{ pl: "15px", pt: "10px" }}
								variant="overline"
								display="block"
								gutterBottom
							>
								Konto
							</Typography>
							{settings.map((entry: any, i: number) => (
								<MenuItem
									key={i}
									onClick={() => {
										handleCloseUserMenu();
										navigate(entry.link);
									}}
									sx={{ pl: "20px" }}
								>
									<Typography textAlign="center">
										{entry.name}
									</Typography>
								</MenuItem>
							))}
							{/* LOGOUT */}
							<SignOutButton />
						</div>
					</div>
				</Menu>
			</div>
		</>
	);
};

export const SignOutButton: React.FC = () => {
	const handleSignOut = useCentralStore((state) => state.handleSignOut);
	return (
		<MenuItem
			key={99}
			onClick={() => {
				handleSignOut();
			}}
			sx={{ pl: "20px" }}
		>
			<Typography textAlign="center">Logout</Typography>
		</MenuItem>
	);
};
