import React, { Suspense, useState, useRef, useEffect } from "react";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { STLLoader } from "three-stdlib";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box3, Vector3, Mesh, PerspectiveCamera } from "three";
import { supabase } from "@/lib/supabase";
import { Logger } from "@/lib/logger/Logger";
import { StorageBucketsEnum } from "../types/enums";
import { Loading } from "@/components/src/animations/loading";
import { useCentralStore } from "../store/Central";
import {
	ActivityEntityType,
	FileWithShare,
	isShared,
} from "@/lib/supabase/supabaseTypes";
import { useRealtimeStore } from "@/lib/store/Realtime";
import { ReplyThread } from "@/components/src/activity-history/thread-sidebar/thread-sidebar.component";

interface STLViewerDialogProps {
	file: FileWithShare;
	fileName: string;
	onClose: () => void;
}

const STLViewer: React.FC<{ stlUrl: string }> = ({ stlUrl }) => {
	const ref = useRef<Mesh>(null);
	const geometry = useLoader(STLLoader, stlUrl);
	const { camera } = useThree();

	useEffect(() => {
		if (geometry && ref.current) {
			const boundingBox = new Box3().setFromObject(ref.current);
			const center = new Vector3();
			boundingBox.getCenter(center);
			ref.current.position.sub(center);

			const perspectiveCamera = camera as PerspectiveCamera;

			if (perspectiveCamera.isPerspectiveCamera) {
				const size = boundingBox.getSize(new Vector3());
				const maxDim = Math.max(size.x, size.y, size.z);
				const fov = perspectiveCamera.fov * (Math.PI / 180);
				const cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));

				perspectiveCamera.position.z = cameraZ * 1.25;
			}
		}
	}, [geometry, camera]);

	return (
		<mesh ref={ref} geometry={geometry} castShadow receiveShadow>
			<meshStandardMaterial
				color="orange"
				metalness={0.5}
				roughness={0.2}
			/>
		</mesh>
	);
};

const STLViewerDialog: React.FC<STLViewerDialogProps> = ({
	file,
	fileName,
	onClose,
}) => {
	const [open, setOpen] = useState(true);
	const [stlUrl, setStlUrl] = useState<string | null>(null);
	const { client } = useCentralStore((state) => ({
		client: state.client,
	}));

	useEffect(() => {
		handleOpen();
	}, []);

	const handleOpen = async () => {
		setOpen(true);

		const { data, error } = await supabase.storage
			.from(StorageBucketsEnum.V1)
			.createSignedUrl(fileName, 3600, {});

		if (error) {
			Logger.error("Error fetching file URL:", error.message);
			return;
		}

		if (data) {
			setStlUrl(data.signedUrl);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setStlUrl(null);
		onClose();
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
				<DialogContent className="w-full">
					{stlUrl ? (
						<div className="flex">
							<Canvas
								style={{
									height: "620px",
								}}
								shadows
								camera={{ position: [0, 0, 10], fov: 50 }}
							>
								<ambientLight intensity={0.4} />

								<directionalLight
									intensity={1.5}
									position={[5, 5, 5]}
									castShadow
									shadow-mapSize-width={2048}
									shadow-mapSize-height={2048}
								/>

								<pointLight
									position={[-10, -10, -10]}
									intensity={0.6}
								/>

								<spotLight
									intensity={1}
									position={[10, 15, 10]}
									angle={0.3}
									castShadow
									shadow-mapSize-width={1024}
									shadow-mapSize-height={1024}
								/>

								<Suspense fallback={null}>
									<STLViewer stlUrl={stlUrl} />
								</Suspense>

								<OrbitControls />
							</Canvas>
							{client?.connect_relationship_id &&
								isShared(file) && (
									<div className="w-1/3 border-l pl-4">
										<ReplyThread
											activityId={
												file.shared_ids?.[0] as number
											}
										/>
									</div>
								)}
						</div>
					) : (
						<div className="h-[500px] flex items-center justify-center w-full">
							<Loading />
						</div>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default STLViewerDialog;
