import { useCentralStore } from "@/dentlab/src/store/Central";
import {
	CommandDialog,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@lab/src/components/ui/command";
import { NEW_DOCUMENT_OPTIONS } from "./new-job-document-area/new-document-options";
import { useJobStore } from "@/dentlab/src/store/Jobs";

export const JobDocumentDialog: React.FC<{
	open: boolean;
	setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => {
	const {
		createJobDocument,
		createJobDocumentFromTemplate,
		isCreateJobDocumentAllowed,
	} = useJobStore((state) => ({
		createJobDocument: state.createJobDocument,
		createJobDocumentFromTemplate: state.createJobDocumentFromTemplate,
		isCreateJobDocumentAllowed: state.isCreateJobDocumentAllowed,
	}));

	const jobDocumentTemplateOptions = useCentralStore((state) =>
		Object.values(state.jobDocumentTemplatesLookup).map((template) => ({
			label: template.title,
			id: template.id,
			type: template.type,
		}))
	);

	return (
		<CommandDialog onOpenChange={setOpen} open={open}>
			<CommandInput
				placeholder="Auftragsvorlage suchen…"
				className="px-4"
			/>{" "}
			<CommandList className="w-[640px] h-96 max-h-96">
				<CommandGroup heading="Leere Dokumente">
					{NEW_DOCUMENT_OPTIONS.map((option) => (
						<CommandItem
							onSelect={() => {
								createJobDocument(option.value);
								setOpen(false);
							}}
							disabled={
								!isCreateJobDocumentAllowed(option.value)
									.isAllowed
							}
							key={option.value}
							className="cursor-pointer hover:bg-gray-100 gap-2"
							value={`${option.label} ${option.value}`}
						>
							{option.icon}
							<span>{option.label}</span>
						</CommandItem>
					))}
				</CommandGroup>{" "}
				<CommandGroup heading="Jumbos/Vorlagen" className="w-full">
					{jobDocumentTemplateOptions.map((jd) => {
						return (
							<CommandItem
								onSelect={() => {
									createJobDocumentFromTemplate(jd.id);
									setOpen(false);
								}}
								key={jd.id}
								value={jd.label ?? jd.id.toString()}
							>
								<span>{jd.label}</span>
							</CommandItem>
						);
					})}
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	);
};
