import { StateCreator } from "zustand";
import { adjustJobStatus } from "./utils";
import { Logger } from "@/lib/logger/Logger";
import { goBackOrForwardDays } from "../../lib/utils/utils-functions";
import { supabase } from "@/lib/supabase";
import {
	JobEntityType,
	JobWithShare,
	SupabaseShareEntityViewEnum,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { JobStatusEnum } from "@/lib/types/job";
import { useCentralStore } from "../Central";
import { showNotification } from "../Central/selectors";
import { produce } from "immer";
import { TpTier, TpValue } from "../../types/enums";
import { usePatientStore } from "../Patient";
import { JobStoreUnion } from ".";
import {
	initialJobDocumentsState,
	JobDocumentWithFiles,
} from "./job-documents.store";
import { initialJobListState } from "./job-list.store";
import { initialJobItemState } from "./job-items.store";

interface JobState {
	job: JobWithShare | null;
	jobLoading: boolean;
	pendingChanges: boolean;
}

export const initialJobState: JobState = {
	job: null,
	jobLoading: false,
	pendingChanges: false,
};

export interface JobSlice extends JobState {
	handleJobRouteChange: (jobIdParam: string) => Promise<void>;
	// TODO: Currently fetchJob is only used in job-general.form.tsx and it might make sense to remove it
	fetchJob: (jobId: number) => Promise<void>;
	createJob: () => Promise<JobEntityType | null>;
	updateGuarantor: (guarantorId: string | null) => Promise<void>;
	deleteJob: (jobId: number, jobStatus: JobStatusEnum) => Promise<void>; // A jobId is needed because the job can also be deleted from the job list (e.g. archiving a job)
	changeJobStatus: (
		jobId: number, // A jobId is needed because the job can also be changed from the job list (e.g. archiving a job)
		currentStatus: JobStatusEnum,
		targetStatus: JobStatusEnum
	) => Promise<boolean>;
	updateJob: (
		field: "title" | "code" | "patient_id",
		value: string | number
	) => Promise<boolean>;
	reset: () => void;
	setPendingChanges: (pendingChanges: boolean) => void;
	pendingChangesNotification: () => void;
}

export const createJobStore: StateCreator<JobStoreUnion, [], [], JobSlice> = (
	set,
	get
) => ({
	...initialJobState,
	handleJobRouteChange: async (jobIdParam: string) => {
		const jobId = Number(jobIdParam);
		if (!jobIdParam || jobId === get().job?.id) return;

		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.JOBS_WITH_SHARES)
			.select(
				`*, ${SupabaseTableEnum.JOB_DOCUMENTS} (*,${SupabaseShareEntityViewEnum.FILES_WITH_SHARES}(*))`
			)
			.eq("id", jobId)
			.single();

		if (error) {
			Logger.error("Error fetching job", error);
			return;
		}

		const { job_documents, ...job } = data;

		set({
			job: job,
			jobDocuments: job_documents.reduce(
				(acc, curr) => {
					acc[curr.id] = curr;
					return acc;
				},
				{} as Record<number, JobDocumentWithFiles>
			),
		});
	},
	setPendingChanges: (pendingChanges: boolean) => {
		set({
			pendingChanges,
		});
	},
	createJob: async () => {
		set({
			jobLoading: true,
		});
		const { client, organization } = useCentralStore.getState();

		if (!client || !organization || !organization?.id) {
			showNotification({
				message:
					"Fehler beim Erstellen des Auftrags durch fehlende Daten.",
				type: "error",
			});
			Logger.error(
				"Unexpected error creating job. Missing client or organization data."
			);
			return null;
		}
		const taxRateForJob = organization.mwst_percentage ?? 8.1;
		const tpValueForJob =
			client.default_tp_value !== null
				? client.default_tp_value
				: (organization.default_tp_value ?? TpValue.NEW);
		const tpVariationForJob = client.default_tp_variation ?? 0;
		const tpTierForJob =
			client.default_tp_tier !== null
				? client.default_tp_tier
				: (organization.default_tp_tier ?? TpTier.PP2);

		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.insert({
				code: "", // If code = '' it will be set on insert on the db
				client_id: client.id,
				title: "Neuer Auftrag",
				status: JobStatusEnum.IN_PROGRESS,
				organization_id: organization?.id,
				incoming_order_date: new Date().toISOString(),
				outgoing_order_date: goBackOrForwardDays(7),
				tax_rate: taxRateForJob,
				guarantor_id: client.guarantor_id,
				tp_tier: tpTierForJob,
				tp_value: tpValueForJob,
				tp_variation: tpVariationForJob,
			})
			.select()
			.single();

		if (error) {
			showNotification({
				message: "Fehler beim Erstellen des Auftrags",
				type: "error",
			});
			Logger.error(error);
			return null;
		}

		if (data) {
			Logger.info({
				message: "Auftrag erstellt",
				type: "success",
			});
			set(
				produce((state) => {
					state.job = {
						...data,
						job_documents: [],
					};
					state.jobDocuments = [];
					state.jobList[JobStatusEnum.IN_PROGRESS].jobs.unshift({
						...data,
						job_documents: [],
					});
					state.jobList[JobStatusEnum.IN_PROGRESS].jobCount += 1;
				})
			);
			set({
				jobLoading: false,
			});
			return {
				...data,
				job_documents: [],
				shared_ids: [],
				connect_relationship_id: null,
			};
		} else {
			showNotification({
				message: "Fehler beim Erstellen des Auftrags",
				type: "error",
			});
			return null;
		}
	},
	fetchJob: async (jobId: number) => {
		if (!jobId) return;

		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.JOBS_WITH_SHARES)
			.select(
				`*, ${SupabaseTableEnum.JOB_DOCUMENTS} (*,${SupabaseTableEnum.FILES}(*))`
			)
			.eq("id", jobId)
			.single();

		if (error) {
			set({
				job: null,
			});
			return;
		}

		const { job_documents, ...job } = data;

		set(
			produce((state) => {
				state.job = {
					...job,
					shared_ids: job.shared_ids?.filter((j) => j) ?? [],
				};
			})
		);

		// This is for a case where for some reason the patient hasn't fetched yet; could be done in a more clever way
		if (job.patient_id) {
			await usePatientStore.getState().getPatient(job.patient_id);
		}
	},
	deleteJob: async (jobId: number, jobStatus: JobStatusEnum) => {
		let job: JobWithShare | null = null;

		// Optimistic update
		set(
			produce((state) => {
				job = state.job;

				// If the currently selected job is deleted, set it to null
				if (state.job?.id === jobId) {
					state.job = null;
				}

				// Remove the job from the list of its current status
				const jobIndex = state.jobList[jobStatus].jobs.findIndex(
					(j: JobWithShare) => j.id === jobId
				);
				if (jobIndex !== -1) {
					state.jobList[jobStatus].jobCount -= 1;
					state.jobList[jobStatus].jobs.splice(jobIndex, 1);
				}
			})
		);
		const { error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.delete()
			.eq("id", jobId);

		if (error) {
			Logger.error("Error deleting job", error);
			set(
				produce((state) => {
					state.job = job;
					state.jobList[jobStatus].jobCount += 1;
					state.jobList[jobStatus].jobs.shift(job);
				})
			);

			showNotification({
				message: "Fehler beim Löschen des Auftrags",
				type: "success",
			});
			return;
		}
		showNotification({
			message: "Auftrag gelöscht",
			type: "success",
		});
	},

	/**
	 * updateGuarantor
	 * Allowed if: No job document has been created that has job items
	 */
	updateGuarantor: async (guarantorId: string | null) => {
		const { job } = get();
		if (!job) {
			showNotification({
				message: "Auftrag ist nicht gesetzt",
				type: "error",
			});
			return;
		}

		const someJobDocumentWithJobItems = Object.values(
			get().jobDocuments
		).some(({ id }) => get().jobItemsForDocuments[id]?.length > 0);
		if (someJobDocumentWithJobItems) {
			showNotification({
				message:
					"Der Garant kann nicht mehr geändert werden, da bereits Dokumente mit Positionen erstellt wurden",
				type: "error",
			});
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.update({ guarantor_id: guarantorId })
			.eq("id", job.id as number);

		if (error) {
			showNotification({
				message: "Fehler beim Aktualisieren des Garanten",
				type: "error",
			});
			Logger.log(error);
			return;
		}

		if (data) {
			set({
				job: {
					...job,
					guarantor_id: guarantorId,
				},
			});
			showNotification({
				message: "Garant aktualisiert",
				type: "success",
			});
		}
	},

	changeJobStatus: async (
		jobId: number,
		currentStatus: JobStatusEnum,
		targetStatus: JobStatusEnum
	) => {
		// Optimistic update
		set(
			produce((state) => {
				adjustJobStatus(state, jobId, targetStatus, currentStatus);
			})
		);
		const { error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.update({ status: targetStatus })
			.eq("id", jobId);

		if (error) {
			set(
				produce((state) => {
					// Revert the changes
					adjustJobStatus(state, jobId, currentStatus, targetStatus);
				})
			);
			return false;
		}

		return true;
	},

	updateJob: async (
		field: "title" | "code" | "patient_id",
		value: string | number
	) => {
		let previousJob: JobWithShare | null = null;
		let jobId: number | null = null;
		let jobStatus: JobStatusEnum | null = null;
		let jobIndex: number = -1;
		set(
			produce((state) => {
				previousJob = state.job;
				jobId = state.job?.id;
				jobStatus = state.job?.status as JobStatusEnum;
				jobIndex = state.jobList[jobStatus].jobs.findIndex(
					(j: JobWithShare) => j.id === jobId
				);

				state.job[field] = value;
				if (jobIndex !== -1) {
					state.jobList[jobStatus].jobs[jobIndex][field] = value;
				}
			})
		);

		if (!jobId) {
			showNotification({
				message: "Auftrag ist nicht gesetzt",
				type: "error",
			});
			return false;
		}

		const { error } = await supabase
			.from(SupabaseTableEnum.JOBS)
			.update({ [field]: value })
			.eq("id", jobId);

		if (error) {
			set(
				produce((state) => {
					state.job = previousJob;
					if (jobIndex !== -1 && jobStatus) {
						state.jobList[jobStatus].jobs[jobIndex] = previousJob;
					}
				})
			);
			showNotification({
				message:
					"Fehler beim Aktualisieren des Auftrags in der Datenbank",
				type: "error",
			});
			return false;
		} else {
			showNotification({
				message: "Auftrag aktualisiert",
				type: "success",
			});
		}

		return true;
	},
	reset: () => {
		set({
			...initialJobState,
			...initialJobDocumentsState,
			...initialJobListState,
			...initialJobItemState,
		});
	},
	pendingChangesNotification: () => {
		if (get().pendingChanges) {
			showNotification({
				message:
					"Ausführung trotz ungespeicherter Auftragsinformationen.",
				type: "warning",
			});
		}
	},
});
