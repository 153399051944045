import { InputAdornment } from "@mui/material";
import {
	CustomTextField,
	CustomTextFieldProps,
} from "./custom-text-field.component";
import "./custom-text-field.css";

export const CustomTextFieldWithPercentage: React.FC<CustomTextFieldProps> = (
	props
) => {
	return (
		<CustomTextField
			{...props}
			type="number"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">%</InputAdornment>
				),
			}}
		/>
	);
};
