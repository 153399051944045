import React from "react";
import { ActivityType } from "../../../dentlab/src/types/types";
import PersonIcon from "@mui/icons-material/Person";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MessageIcon from "@mui/icons-material/Message";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Description, Mail, UpdateRounded } from "@mui/icons-material";

export const ActivityIcon: React.FC<{
	activityType: ActivityType | null;
}> = ({ activityType }) => {
	switch (activityType) {
		case "job_request_status":
			return <UpdateRounded />;
		case "shared_file":
			return <Description />;
		case "message_general":
			return <MessageIcon />;
		case "shared_job":
			return <AddBoxIcon />;
		case "shared_document":
			return <InsertDriveFileIcon />;
		case "shared_patient":
			return <PersonIcon />;
		case "shared_job_request":
			return <Mail />;
		default:
			return <div />;
	}
};
