import { GridCustomCellProps } from "@progress/kendo-react-grid";
import { TextCustomCell } from "./text-custom-cell.component";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";

export const TPValueCustomCell: React.FC<GridCustomCellProps> = (props) => {
	const isTariff = props.dataItem?.type === JobItemTypeEnum.TARIFF;
	const tpValue = props.dataItem["tp_value"];

	if (!isTariff && tpValue === 0) {
		return <td className="custom-td w-full"></td>;
	}

	const error = (tpValue === 0 && isTariff) || (tpValue !== 0 && !isTariff);
	return (
		<>
			<TextCustomCell
				{...props}
				style={{ border: `${error ? "1px solid red" : ""}` }}
			/>
		</>
	);
};
